@import "../../global.less";

.Guides---What-is-a-Clinical-Trial---S {
  width           : 91%;
  margin-left     : 4.5%;
  margin-right    : 4.5%;
  background-color: #f6f6f6;

  @media @m {
    width       : 95%;
    margin-left : 2.5%;
    margin-right: 2.5%;
  }

  @media @xl {
    width       : 90%;
    margin-left : 5%;
    margin-right: 5%;
  }

  @media @xxl {
    width       : 100%;
    margin-left : 0%;
    margin-right: 0%;
  }
}

.Guide-breabrumb {
  display: none;
  width  : 350px;
  float  : right;

  @media @l {
    display: block;
  }

  @media @l {
    width : 350px;
    margin: 32px 0px 32px 32px;
  }

  @media @xl {
    width : 350px;
    height: 64px;
    margin: 32px 0px 32px 80px;
  }

  @media @xxl {
    width : 350px;
    margin: 32px 0px 32px 0px;
  }
}

.CSL-breadCrumb {
  width         : 31px;
  height        : 25px;
  margin        : 0 2px 0 0;
  font-family   : Montserrat;
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.56;
  letter-spacing: normal;
  text-align    : right;
  color         : red !important;
}

.Path-breadCrumb {
  color      : #7b7d7e !important;
  width      : 24px;
  height     : 24px;
  margin     : 1px 2px 0;
  padding    : 5px 9px 6px 8px;
  font-size  : 18px;
  font-weight: 500;
}

.Learn-breadCrumb {
  width         : 46px;
  height        : 25px;
  margin        : 0 2px;
  font-family   : Montserrat;
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.56;
  letter-spacing: normal;
  text-align    : right;
  color         : red !important;
}

.What-is-a-Clinical-T-breadCrumb {
  width         : 175px;
  height        : 25px;
  margin        : 0 0 0 2px;
  font-family   : Montserrat;
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.56;
  letter-spacing: normal;
  text-align    : right;
  color         : #7b7d7e !important;
}

.RectangleGuide {
  width        : 105px;
  height       : 40px;
  margin       : 32px 0px 32px 0px;
  padding      : 8px 22px 8px 8px;
  border-radius: 32px;
  border       : solid 1px red;

  @media @m {
    margin: 32px 0px 32px 0px;
  }

  @media @l {
    margin: 32px 0px 32px 0px;
  }

  @media @xl {
    width : 141px;
    height: 64px;
    margin: 32px 0px 32px 0px;
  }

  @media @xxl {
    margin: 32px 0px 32px 0px;
  }
}

.OvalGUide {
  width          : 19px;
  height         : 19px;
  background-size: 100%;
  justify-self   : flex-end;
  display        : block;
  margin-left    : 3px;
  float          : left;

  @media @xl {
    width : 35px;
    height: 35px;

  }
}

.Tertiary-Button {
  width            : 43px;
  height           : 16px;
  /* padding-bottom: 37px; */
  margin           : 3px 0 4px 8px;
  font-family      : Montserrat;
  font-size        : 14px;
  font-weight      : bold;
  font-stretch     : normal;
  font-style       : normal;
  line-height      : 1.14;
  letter-spacing   : normal;
  color            : crimson;
  float            : right;

  @media @xl {
    width     : 63px;
    height    : 16px;
    font-size : 16px;
    margin-top: 10px;
  }
}

.What-is-a-Clinical-T {
  width         : 100%;
  margin-bottom : 16px;
  font-family   : Montserrat;
  font-size     : 30px;
  font-weight   : 300;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.17;
  letter-spacing: normal;
  color         : black;

  @media @m {
    width    : 100%;
    font-size: 30px;
  }

  @media @l {
    width    : 100%;
    font-size: 60px;
  }

}

.A-clinical-trial-is {
  width         : 100%;
  margin        : 16px 0px 32px 0px;
  font-family   : Montserrat;
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.7;
  letter-spacing: normal;
  color         : black;

  @media @m {
    width        : 100%;
    margin-bottom: 40px;
  }

  @media @l {
    width : 100%;
    height: 54px;
    margin: 16px 0px 48px 0px;
  }

  @media @xl {
    margin: 16px 0px 16px 0px;
    width : 80%;
  }

  @media @xxl {
    width : 74%;
    margin: 16px 0px 16px 0px;
  }
}

.Why-is-it-important {
  width         : 100%;
  margin        : 0 0px 16px 0px;
  font-family   : Montserrat;
  font-size     : 20px;
  font-weight   : 300;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.5;
  letter-spacing: normal;
  color         : black;

  @media @l {
    font-size: 40px;
  }

  @media @xl {
    margin: 32px 0px 16px 0px;
  }

}

.Div-Clinical-trials-cann {
  width: 100%;

  @media @m {
    float: left;
  }

  @media @l {
    margin-bottom: 10px;

  }
}

.Div-Clinical-trials-cann2 {
  width: 100%;
}

.Div-Clinical-trials-cann1 {
  width: 100%;
}

.Div-Clinical-trials-source {
    margin-left: 32px;
}


.Bitmap-Guide-what-is-clinical-trial {
  width          : 100%;
  height         : 192px;
  margin         : 16px 0px 16px 0px;
  background-size: 100% 192px;

  @media @m {
    width          : 36%;
    height         : 148px;
    float          : left;
    margin         : 0px 2% 16px 0px;
    background-size: 100% 148px;
  }

  @media @l {
    width          : 33%;
    height         : 173px;
    background-size: 100% 173px;
    margin         : 16px 2% 16px 0px;
  }

  @media @xl {
    width          : 31%;
    height         : 231px;
    background-size: 100% 231px;
    margin         : 8px 32px 16px 0px;
  }

  @media @xxl {
    width          : 467px;
    height         : 261px;
    background-size: 467px 261px;
    margin         : 16px 32px 16px 0px;
  }
}

.Bitmap-Guide-what-is-clinical-trial1 {
  width          : 100%;
  height         : 192px;
  margin         : 16px 0px 16px 0px;
  background-size: 100% 192px;

  @media @m {
    width          : 36%;
    height         : 148px;
    float          : right;
    margin         : 0px 0px 16px 4%;
    background-size: 100% 149px;
  }

  @media @l {
    width          : 31%;
    height         : 174px;
    margin         : 0px 0px 16px 0px;
    background-size: 100% 174px;
  }

  @media @xl {
    width          : 31%;
    height         : 231px;
    margin         : 0px 0px 16px 0px;
    background-size: 100% 231px;
  }

  @media @xxl {
    width          : 467px;
    height         : 261px;
    background-size: 467px 261px;
    margin         : 0px 0px 16px 0px;
  }
}

.Clinical-trials-cann {
  width         : 100%;
  margin        : 16px 0px 33px 0px;
  font-family   : Montserrat;
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.7;
  letter-spacing: normal;
  color         : black;

  @media @m {
    width : 62%;
    float : right;
    margin: 8px 0px 48px 0px;
  }

  @media @l {
    width : 65%;
    float : right;
    margin: 16px 0px 48px 0px;
  }

  @media @xl {
    width       : 64%;
    height      : 136px;
    margin-right: 1%;
    Margin-top  : 8px;
  }

  @media @xxl {
    width       : 819px;
    height      : 136px;
    margin-right: 0px;
    float       : left;
  }
}

.How-are-clinical-tri {
  width         : 100%;
  margin        : 16px 0px 16px 0px;
  font-family   : Montserrat;
  font-size     : 20px;
  font-weight   : 300;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.5;
  letter-spacing: normal;
  color         : black;

  @media @m {
    margin: 16px 0px 0px 0px;
  }

  @media @l {
    margin   : 0px 0px 24px 0px;
    font-size: 40px;
  }

  @media @xl {
    margin: 32px 0px 24px 0px;
  }

  @media @xxl {
    margin-left: 8%;
    width      : 92%;
  }
}

.Before-a-clinical-tr {
  width         : 100%;
  margin        : 16px 0px 16px 0px;
  font-family   : Montserrat;
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.7;
  letter-spacing: normal;
  color         : black;

  @media @m {
    width     : 60%;
    margin-top: 24px;
  }

  @media @l {
    width : 66%;
    margin: 0px 2% 16px 0px;
  }

  @media @xl {
    width : 64%;
    margin: 0px 0px 16px 0px;
  }

  @media @xxl {
    width      : 55%;
    margin-left: 8%;
  }
}

.div-the-length-of-the {
  width         : 100%;
  margin        : 16px 0px 16px 0px;
  font-family   : Montserrat;
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.7;
  letter-spacing: normal;
  color         : black;

  @media @xxl {
    width        : 55%;
    margin-left  : 8%;
    margin-bottom: 8px;
  }

}

.div-the-length-of-the-point1 {
  width        : 100%;
  font-size    : 16px;
  margin-bottom: 16px;
  float        : left;

  @media @m {
    width: 60%;
  }

  @media @l {
    width: 60%;
  }

  @media @xxl {
    width        : 100%;
    margin-bottom: 24px;
  }
}

.div-the-length-of-the-point {
  width        : 100%;
  font-size    : 16px;
  margin-bottom: 16px;
  float        : left;

  @media @m {
    width: 60%;
  }

  @media @l {
    width: 61%;
  }

  @media @xxl {
    width        : 100%;
    margin-bottom: 24px;
  }
}

.div-the-length-of-the-line {
  width      : 2%;
  font-size  : 32px;
  float      : left;
  line-height: 10px;
  margin-left: 7px;
}

.div-the-length-of-the-text1 {
  width      : 90%;
  font-size  : 16px;
  float      : left;
  margin-left: 4%;

  @media @m {
    width: 90%;
  }

  @media @l {
    width: 90%;
  }
}

.div-the-length-of-the-text {
  width      : 90%;
  font-size  : 16px;
  float      : left;
  margin-left: 4%;

  @media @m {
    width: 90%;
  }

  @media @l {
    width: 92%;
  }
}

.A-research-team-is-a {
  width         : 100%;
  margin        : 16px 0px 16px 0px;
  font-family   : Montserrat;
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.7;
  letter-spacing: normal;
  color         : black;

  @media @m {
    width        : 100%;
    margin-bottom: 32px;
    float        : left;
    margin-top   : 0px;
  }

  @media @xxl {
    width      : 88%;
    margin-left: 8%;
  }
}

.A-research-team-is-a1 {
  width        : 100%;
  font-size    : 16px;
  margin-bottom: 16px;

  @media @m {
    width: 100%;
  }

  @media @l {
    width: 64.3%;
  }


}

.What-are-clinical-ph {
  width         : 100%;
  margin        : 16px 0px 16px 0px;
  font-family   : Montserrat;
  font-size     : 20px;
  font-weight   : 300;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.5;
  letter-spacing: normal;
  color         : black;

  @media @m {
    float     : left;
    margin-top: 0px;
  }

  @media @l {
    font-size: 40px;
    margin   : 0px 0px 32px 0px;
  }

  @media @xl {
    font-size: 40px;
    margin   : 0px 0px 32px 0px;
  }

  @media @xxl {
    margin: 0px 0px 32px 0px;
  }
}

.Clinical-trials-are {
  width         : 100%;
  margin        : 16px 0px;
  font-family   : Montserrat;
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.7;
  letter-spacing: normal;
  color         : black;

  @media @m {
    width      : 62%;
    float      : right;
    margin-left: 0px;
    margin-top : 0px;
  }

  @media @l {
    width: 53.5%;
  }

  @media @xl {
    width      : 65%;
    margin-left: 0px;
  }

  @media @xxl {
    width       : 55%;
    margin-right: 10%;
  }
}

.div-In-Phase-I-trials {
  width         : 100%;
  margin        : 16px 0px 0px 0px;
  font-family   : Montserrat;
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.7;
  letter-spacing: normal;
  color         : black;


  @media @l {
    width      : 54.5%;
    float      : right;
    margin-left: 0px;
  }

  @media @xl {
    width       : 65%;
    margin-right: 0px;
  }

  @media @xxl {
    margin-right: 9%;
    width       : 56%;
  }
}

.div-In-Phase-I-trials-point {
  width        : 100%;
  font-size    : 16px;
  margin-bottom: 16px;
  float        : left;


}

.div-In-Phase-I-trials-line {
  width      : 2%;
  font-size  : 32px;
  float      : left;
  line-height: 10px;
  font-weight: 600;
  margin-left: 7px;

  @media @l {
    margin-left: 10px;
  }

  @media @xl {
    width: 1%;
  }
}

.div-In-Phase-I-trials-text {
  width      : 90%;
  font-size  : 16px;
  float      : left;
  margin-left: 5%;

  @media @m {
    width      : 93%;
    margin-left: 3%;
  }

  @media @l {
    width      : 90%;
    margin-left: 3%;
  }

  @media @xl {
    width      : 95%;
    margin-left: 2%;
  }
}

.margin-bot {
  margin-bottom: 0px;
}

.text-style-1 {
  font-weight: bold;
  float      : left;

  @media @m {
    float: left;
  }
}

.Bitmap-clinical-phases {
  @media @l {
    width          : 43%;
    height         : 231px;
    background-size: 100% 231px;
    margin-top     : 0px;
  }

  @media @xl {
    width          : 32%;
    height         : 231px;
    background-size: 100% 231px;
  }
}