@import "../../global.less";

.inner-content {
  margin-top: 32px;

  @media @m {
    margin: 0px 95px;
  }

  @media @l {
    margin: 0px;
  }
}
hr.message-hr {
  margin-bottom: 32px;

  @media @l {
    margin-bottom: 24px;
  }

  @media @xl {
    margin-bottom: 32px;
  }
}

.trial-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 16px 12px 32px 12px;

  @media @m {
    margin: 16px 100px 32px 100px;
  }

  @media @l {
    justify-content: flex-start;
    margin: 24px 0px 24px 0px;
  }
}

.trial-menu a.trial-link {
  display: inline-block;
  background-size: 44px;
  background-repeat: no-repeat;
  background-position: center top;
  font-size: 12px;
  color: @darkbrown;
  text-align: center;
  padding-top: 44px;

  @media @l {
    background-size: 32px;
    line-height: 32px;
    font-size: 16px;
    background-position: left center;
    padding: 0px 0px 0px 40px;
    margin-right: 32px;
  }
}
.CBL-trial-hide {
  display: none;
}
.Combined-Shape-MultiSession {
  width: 370px;
  padding: 16px;
  min-height: 150px;
  max-height: 300px;
}
.Important-Multi-ses {
  font-size: 16px !important;
  font-weight: 600;
}
.This-session-was-has {
  font-size: 14px !important;
  margin-bottom: 24px;
}
.Rectangle-Multi-Ses {
  width: 116px !important;
  padding: 14px !important;
  margin: 0 33%;
}
.Border-Rectangle {
  height: 1px;
  margin: 8px 0px 8px 0px;
  background-color: #e2dfda;
}
