@import "../../global.less";

.Rectangle-guide {
  width: 100%;
  height: 700px;
  margin: 0px 0px 32px;
  padding: 0 0px 24px 0;
  box-shadow: 0 4px 8px 0 rgba(226, 223, 218, 0.5);
  border: solid 1px #e2dfda;
  background-color: white;

  @media @m {
    height: 460px;
  }

  @media @l {
    height: 460px;
  }

  @media @xl {
    width: 32%;
    height: 770px;
    float: left;
  }

  @media @xxl {
    height: 770px;
    float: left;
  }
}

.rightdiv {
  height: 555px;

  @media @m {
    height: 460px;
  }

  @media @l {
    height: 460px;
    margin-bottom: 0px;
  }

  @media @xl {
    height: 770px;
  }

  @media @xxl {
    margin-bottom: 0px;
  }
}

.conditiondivheight {
  height: 715px;
  @media @m {
    height: 460px;
  }

  @media @xl {
    height: 770px;
  }
}
.conditiondiv {
  height: 735px;

  @media @m {
    height: 420px;
  }

  @media @l {
    height: 432px;
  }

  @media @xl {
    height: 770px;
  }

  @media @xxl {
    margin-bottom: 0px;
  }
}

.marginright {
  margin-right: 18px;

  @media @m {
    margin-right: 32px;
  }

  @media @l {
    margin-right: 18px;
  }

  @media @xxl {
    margin-right: 20px;
  }
}

.Bitmap-img {
  width: 100%;
  height: 227px;
  margin: 0 0px 0px 0;
  float: left;
  background-size: cover;
  background-position: center;

  @media @m {
    width: 100%;
    height: 274px;
  }
}

.leftdiv {
  width: 100%;
  float: left;

  @media @m {
    width: 50%;
  }

  @media @l {
    width: 41%;
  }

  @media @xl {
    width: 100%;
  }
}

.margin-top {
  border-top: 1px #e2dfda;
  border-top-style: double;
  width: 92%;
  float: left;
  margin: 0px 16px 0px;

  @media @m {
    width: 42%;
    float: right;
    margin-top: 20px;
    margin-left: 4%;
    margin-right: 4%;
  }

  @media @l {
    width: 51%;
    float: right;
  }

  @media @xl {
    width: 84%;
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 0px;
  }

  @media @xxl {
    width: 86%;
    margin-left: 7%;
    margin-right: 7%;
  }
}

.Guides {
  width: 92%;
  margin: 16px 4% 16px 4%;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black;
  float: left;

  @media @m {
    margin: 16px 4% 24px 8%;
  }

  @media @l {
    width: 96%;
    margin: 24px 4% 16px 8%;
  }

  @media @xl {
    width: 88%;
  }

  @media @xxl {
    width: 86%;
    margin: 24px 7% 16px 7%;
  }
}

.Lean-what-to-expect {
  width: 92%;
  margin: 16px 4% 24px 4%;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: black;
  float: left;

  @media @m {
    margin: 0px 0px 0px 8%;
    width: 90%;
  }

  @media @l {
    width: 91%;
    margin: 0px 0px 24px 8%;
  }

  @media @xl {
    width: 84%;
    margin: 0px 8% 24px 8%;
  }

  @media @xxl {
    width: 86%;
    margin: 0px 7% 24px 7%;
  }
}

.See-more {
  width: 315px;
  height: 16px;
  margin: 24px 0px 0 7px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: red !important;
}

.See-moreDiv {
  width: 315px;
  float: left;
  height: 50px;
  margin-top: 17px;

  @media @xl {
    float: inherit;
    width: 355px;
  }

  @media @xxl {
    float: left;
  }
}

.rightdivguide {
  height: 670px;

  @media @m {
    height: 460px;
  }

  @media @xl {
    height: 770px;
  }
}
