@import "../../global.less";

.hero {
  top: 0;
  height: 320px;
  width: 100%;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: absolute;

  @media @m {
    height: 538px;
    background-position-y: 0px;
    background-size: 133%;
  }

  @media @l {
    height: 720px;
    background-size: cover;
  }
}

.hero-callout {
  position: relative;
  margin-top: 256px;
  margin-bottom: 48px;
  @media @m {
    padding: 32px;
    margin-top: 398px;
    margin-bottom: 64px;
  }

  @media @l {
    padding: 32px !important;
    margin-top: 399px;
  }

  @media @xl {
    margin-top: 400px;
    margin-left: 108px;
    margin-right: 108px;
  }

  @media @xxl {
    padding: 32px 65px !important;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.hero-h1 {
  margin-bottom: 16px;
  padding: 0px 2px;
  @media @m {
    margin-bottom: 24px;
    padding: 0px 50px;
  }

  @media @l {
    padding: 0px;
  }
}

.hero-h2 {
  margin-bottom: 32px;
  @media @m {
    margin-bottom: 48px;
  }
}

//below hero
.explore {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media @m {
    margin-bottom: 48px;
  }
}

.explore.eright {
  @media @l {
    flex-direction: row-reverse;
    align-items: flex-start;
  }
}

.explore.eleft {
  @media @l {
    flex-direction: row;
    align-items: flex-start;
  }
}

.explore .eimg {
  height: 224px;
  width: 100%;
  background-position: 0 0;
  background-size: cover;
  z-index: 0;

  @media @m {
    width: 408px;
    height: 266px;
    margin-bottom: -16px;
  }

  @media @l {
    align-self: inherit;
    margin-bottom: 0;
  }

  @media @xl {
    width: 650px;
    height: 424px;
  }
}

.explore.especialties .eimg {
  background-position: center;
}

.explore.eright .eimg {
  @media @m {
    align-self: flex-end;
  }

  @media @l {
    align-self: inherit;
    margin-right: -32px;
  }

  @media @xl {
    margin-right: -80px;
  }

  @media @xxl {
    margin-right: -250px;
  }
}

.explore.eleft .eimg {
  @media @m {
    align-self: flex-start;
  }

  @media @l {
    align-self: inherit;
    margin-left: -32px;
  }

  @media @xl {
    margin-left: -80px;
  }

  @media @xxl {
    margin-left: -250px;
  }
}

.explore .ebox {
  padding: 16px 16px 24px 16px;
  background-color: @white;

  @media @m {
    padding: 24px;
  }

  @media @l {
    flex: 1;
  }
}

.explore.eright .ebox {
  @media @l {
    margin-left: 33px;
    margin-top: 40px;
    margin-right: -84px;
    padding-right: 115px;
  }

  @media @xl {
    padding: 32px;
    margin: 0px 0px 0px 80px;
  }

  @media @xxl {
    margin-left: 168px;
  }
}

.explore.eleft .ebox {
  @media @l {
    margin-right: 33px;
    margin-top: 40px;
    margin-left: -84px;
    padding-left: 115px;
  }

  @media @xl {
    padding: 32px;
    margin: 0px 80px 0px 0px;
  }

  @media @xxl {
    margin-right: 168px;
  }
}

.explore .ebox p {
  margin-bottom: 24px;

  @media @l {
    margin-bottom: 51px;
  }

  @media @xl {
    margin-bottom: 24px;
  }
}

.meet-our-patients {
  display: flex;
  flex-direction: column;

  @media @xxl {
    margin: 0px -121px;
  }
}

.meet-area {
  position: relative;
}

.meet-our-patients h1 {
  font-family: SignPainter-HouseScript;
  font-size: 44px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: @rollingstone;
  padding: 0;

  @media @m {
    font-size: 64px;
    line-height: 0.94;
  }
}

.slick-dots {
  bottom: 0px;
  position: relative;
  margin-top: 16px;
}

.slick-dots li button {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: @westar;
}

li.slick-active button {
  background-color: @torch;
}

.slick-dots li button:before {
  content: "";
}

.next-arr,
.prev-arr {
  top: 40%;
  position: absolute;
  height: 44px;
  width: 44px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 100;
  cursor: pointer;
}

.prev-arr {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: 16px;
}

.next-arr {
  right: 16px;
}

.slick-list {
  display: flex !important;
}

.slick-track {
  flex: 1;
  display: flex !important;
  flex-direction: row;
}

.slick-slide {
  display: flex !important;
  min-height: 100% !important;
  flex: 1;
  min-height: 100% !important;
}

.slick-slide > div {
  display: flex;
  flex: 1;
}

.slider-box {
  width: 100%;
  display: flex !important;
  flex-direction: column;

  @media @m {
    height: 340px;
    flex-direction: row;
  }
}

.slider-box-img {
  width: 100%;
  height: 340px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.slider-box-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: @rollingstone;
  padding: 16px 16px 24px 16px;
  position: relative;
  overflow: hidden;
  z-index: 100;

  @media @m {
    flex: auto;
    background-color: transparent;
    margin-right: -90px;
    padding: 32px 64px 32px 32px;
    width: 600px;
  }
}

.slider-box-content-shape {
  display: none;

  @media @m {
    display: block;
    top: 0;
    bottom: 0;
    left: -30px;
    right: 0px;
    margin: 0px 50px 0px 0px;
    transform: skew(-10deg);
    -webkit-transform: skew(-10deg);
    -moz-transform: skew(-10deg);
    -o-transform: skew(-10deg);
    background: @rollingstone;
    position: absolute;
    box-shadow: 0 0 12px 10px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 12x 10px rgba(0, 0, 0, 0.3);
  }
}

.slider-box-content h4 {
  color: @white;
  margin-bottom: 32px;
  z-index: 50;
  flex: 1;
}

.slider-box-content button {
  align-self: center;
  z-index: 50;

  @media @m {
    align-self: flex-start;
  }
}

.get-involved-callout {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  background-color: @white;
  padding: 24px;

  @media @l {
    padding: 24px 32px;
    flex-direction: row;
  }

  @media @xxl {
    padding: 24px 64px;
    margin: 16px -121px 0px -121px;
  }
}

.get-involved-callout .involved-content {
  flex: 1;
  padding-right: 105px;
  align-self: flex-start;
}

.get-involved-callout .involved-link {
  margin-top: 32px;
  align-self: flex-start;

  @media @l {
    align-self: center;
    margin-top: 0px;
  }
}

.vita-tag {
  display: flex;
  max-width: 551px;
  width: 100%;
  margin: 48px auto 24px auto;

  @media @m {
    margin-top: 64px;
  }

  @media @l {
    max-width: 638px;
  }

  @media @xl {
    max-width: 822px;
  }
}

.vita-news-head {
  margin-bottom: 24px;

  @media @m {
    margin-bottom: 37px;
  }

  @media @l {
    margin-bottom: 24px;
  }

  @media @xxl {
    margin: 0px -121px 24px -121px;
  }
}

//carousel
.flicking-wrap {
  margin: 0px -16px;

  @media @l {
    margin: 0px 0px;
  }

  @media @xxl {
    margin: 0px -121px;
  }
}

.flicking {
  width: 100%;
  margin: 0px auto;
}

.flicking .panel {
  display: flex;
  top: 0px;
  min-height: 100%;
  width: 292px;
  padding: 0px 8px;

  @media @m {
    width: 266px;
  }

  @media @l {
    width: 309px;
  }

  @media @xl {
    width: 413px;
    padding: 0px 10px;
  }

  @media @xxl {
    width: 466px;
  }
}

.flicking .panel:first-child {
  padding-left: 16px;

  @media @l {
    padding-left: 0px;
  }
}

.flicking .panel:last-child {
  padding-right: 16px;

  @media @l {
    padding-right: 0px;
  }
}

.see-more-stories {
  margin: 40px auto 0px auto;
  text-align: center;
}
