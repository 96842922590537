@import "../../global.less";

.Bitmap {
  top: 0;
  height: 240px;
  width: 100%;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: absolute;

  @media @m {
    width: 100%;
    height: 345px;
    margin: 0 0 158px;
    padding: 0 0 0px;
    background-size: cover;
    background-position: center;
  }

  @media @l {
    width: 100%;
    height: 460px;
    margin: 0 0 230px;
    padding: 0 0 0px;
  }

  @media @xl {
    width: 100%;
    height: 460px;
    margin: 0 0 195px;
    padding: 0 0 0px;
  }
}

.marginright {
  margin-right: 18px;
}

.Learn---XXL {
  width: 1920px;
  height: 1702px;
  background-color: #f6f6f6;
}

.Mask {
  width: 1024px;
  height: 460px;
  margin: 0 0 216px;
  padding: 0 0 342px;
  background-color: #e2dfda;
}

.Rectangle {
  position: relative;
  margin-top: 210px;
  margin-bottom: 48px;

  @media @m {
    margin-top: 312px;
    margin-bottom: 64px;
  }

  @media @l {
    margin-top: 398px;
    margin-bottom: 64px;
  }

  @media @xl {
    margin-top: 398px;
    margin-bottom: 64px;
    width: 80%;
    margin-left: 8%;
  }

  @media @xxl {
    margin-top: 398px;
    margin-bottom: 64px;
    width: 80%;
    margin-left: 9%;
  }
}

.Learn-about-clinical {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: black;

  @media @m {
    margin: 16px;
    padding: 0px 16px;
  }

  @media @l {
    font-size: 40px;
  }

  @media @xl {
    font-size: 40px;
    padding: 0px;
  }

  @media @xxl {
    padding: 0px 32px;
  }
}

.xxlResponsive {
  @media @xxl {
    padding: 0px !important;
  }
}
