@import "../../global.less";

.visit-check label:before {
  top: 3px !important;
  @media @l {
    top: 14px !important;
  }
}

.visit-check label:after {
  top: 10px !important;

  @media @l {
    top: 20px !important;
  }
}

.overview-tasks {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.visit-sub {
  display: block;
  margin-top: 14px;
  font-size: 14px;
  color: @rollingstone;
}

.overview-tasks hr {
  margin: 16px 0px;

  @media @l {
    margin: 32px 0px;
  }
}

.overview-tasks hr:last-child {
  margin: 16px 0px 0px 0px;

  @media @l {
    margin: 32px 0px 0px 0px;
  }
}

.overview-row {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media @m {
    flex-direction: row;
  }
}

.overview-details {
  flex: 1;
}

.overview-icons {
  margin-top: 20px;

  @media @m {
    margin-top: 0px;
  }
}

.overview-icons span:not(:first-child) {
  margin-left: 8px;

  @media @l {
    margin-left: 16px;
  }
}
