@import "../../global.less";

.prescreen-head {
  margin-top: 32px;
}

.prescreen-subhead {
  margin-top: 16px;
  color: @steel;
}

.screening-wrap {
  @media @l {
    margin: 0px 60px;
  }
}

.prescreen-head-box {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 24px 32px;
  margin-top: 24px;

  @media @m {
    flex-direction: row;
  }
}

.prescreen-small-map {
  height: 88px;
  width: 88px;

  margin-bottom: 16px;
}

.prescreen-head-box-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.prescreen-head-box-details h3 {
  margin-bottom: 8px;
}

.prescreen-question-head {
  margin-top: 32px;
}

.prescreen-question-desc {
  margin-top: 16px;
}

.prescreen-questions {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.prescreen-question-item:not(:first-child) {
  margin-top: 24px;
}

.prescreen-question-item-head {
  display: flex;
  flex-direction: row;
  justify-items: center;
  margin-bottom: 8px;
}

.prescreen-question-item-head .question-num {
  align-self: flex-start;
  width: 12px;
}

.prescreen-question-item-head .question-title {
  flex: 1;
  margin: 5px 0px 0px 32px;
}

.prescreen-question-option {
  padding: 16px 16px;
  border: 1px solid @shadow;
  background-color: @white;

  @media @m {
    margin: 0px 48px;
  }
}

.prescreen-question-option:not(:last-child) {
  margin-bottom: 16px;
}

.check-for-match-button {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
}

.check-for-match-button button {
  width: 100%;

  @media @m {
    width: 50%;
    align-self: center;
  }
}
