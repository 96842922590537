@import "../../global.less";

.sidebar-wrap {
  height: 100%;
  display: flex !important;
  flex-direction: column;
}

.sidebar-shadow {
  box-shadow: 4px 0 16px 0 #00000040;
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  width: 280px !important;
  min-height: 100%;

  @media @l {
    display: none;
  }
}

/* General sidebar styles */
.bm-menu {
  background: @darkbrown;
  font-size: 1.15em;
  overflow: unset !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(255, 255, 255, 0.8) !important;

  @media @l {
    display: none;
  }
}

//inner content
.sidebar-logo {
  width: 100px;
  margin-top: 24px;
  margin-left: 24px;

  @media @mobile-landscape {
    margin-left: 48px;
  }
}

.sidemenu-list {
  margin: 0px;
  padding: 0;
  list-style: none;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  @media @mobile-landscape {
    justify-content: space-around;
  }
}

.sidemenu-list li {
  margin-bottom: 60px;
  text-transform: uppercase;

  @media @mobile-landscape {
    margin-bottom: 0px;
  }
}

.sidemenu-list li:first-child {
  text-transform: none;
  margin-bottom: 43px;

  @media @mobile-landscape {
    margin-bottom: 0px;
  }
}
