//res
@screen-xs-min: 375px;
@screen-sm-min: 768px;
@screen-md-min: 1024px;
@screen-l-min: 1440px;
@screen-xl-min: 1920px;

@screen-xs-max: (@screen-sm-min - 1);
@screen-sm-max: (@screen-md-min - 1);
@screen-md-max: (@screen-l-min - 1);
@screen-l-max: (@screen-xl-min - 1);

@mobile-landscape: ~"only screen and (orientation: landscape)";
@s: ~"only screen and (max-width: @{screen-xs-min})";
@s-strict: ~"only screen and (max-width: @{screen-xs-max})";
@m: ~"only screen and (min-width: @{screen-sm-min})";
@m-strict: ~"only screen and (min-width: @{screen-sm-min}) and (max-width: @{screen-sm-max})";
@l: ~"only screen and (min-width: @{screen-md-min})";
@l-strict: ~"only screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";
@xl: ~"only screen and (min-width: @{screen-l-min})";
@xl-strict: ~"only screen and (min-width: @{screen-l-min}) and (max-width: @{screen-l-max})";
@xxl: ~"only screen and (min-width: @{screen-xl-min})";

//res-only
.s-only {
  @media @m {
    display: none;
  }
}

.mobile-only {
  @media @l {
    display: none;
  }
}

.m-and-up {
  @media @s-strict {
    display: none;
  }
}

.l-and-up {
  @media @s-strict, @m-strict {
    display: none;
  }
}

.xl-and-up {
  @media @s-strict, @m-strict, @l-strict {
    display: none;
  }
}

//colors
@black: #000;
@lightgrey: #f6f6f6;
@steel: #808284;
@white: #ffffff;
@darkbrown: #29261e;
@shadow: #453f2e28;
@shadow-light: #e2dfda80;
@light-border: #e2dfda;
@torch: #fc1921;
@deepred: #c9141a;
@lightred: #ed514f;
@darkyellow: #2e2b21;
@pampas: #f1efea;
@westar: #e2dfda;
@rollingstone: #808284;
@green: #c7d741;
@flamingo: #f06125;
@tory: #0e56a5;
@lighttory: #e7eef6;
@darkergrey: #d8d8d8;
@lightergrey: #979797;
@hovergrey: #3d3b34;
@persian: #00a28a;
@pacific: #03b3be;
@purple: #a058a6;
@darkpurple: #da2877;
@greenyellow: #c6d92d;
@lightpurple: #d4b0c0;
//page
#root {
  height: 100%;
}

html,
body {
  background-color: @lightgrey;
  margin: 0;
  padding: 0;
  min-width: 375px;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: @darkbrown;
}

p {
  padding: 0;
  margin: 0;
}

hr {
  display: block;
  border: 0;
  margin: 0;
  padding: 0;
  border-top: 1px solid @light-border;
}

.placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  color: @rollingstone;
}

.hidden {
  visibility: hidden;
}

.nowrap {
  white-space: nowrap;
}

.content-ceiling {
  padding-top: 32px;
}

.content-edge {
  padding-left: 16px;
  padding-right: 16px;

  @media @mobile-landscape {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media @l {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media @xl {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media @xxl {
    padding-left: 121px;
    padding-right: 121px;
  }
}

.content-edge.expanded-xxl {
  @media @xxl {
    padding-left: 0px;
    padding-right: 0px;
  }
}

//fonts
@font-face {
  font-family: "SignPainter-HouseScript";
  src: url("/fonts/SignPainter HouseScript Regular.ttf");
}

.font-15 {
  font-size: 15px;
}

.torch {
  color: @torch;
}

.bold {
  font-weight: bold;
}

//headers
h1,
h2,
h3,
h4 {
  margin: 0px;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: @darkbrown;

  @media @l {
    font-size: 60px;
    font-weight: 200;
    line-height: 1;
  }
}

h1.light {
  color: @westar;
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: @darkbrown;

  @media @l {
    font-size: 40px;
    font-weight: 300;
    line-height: 1.25;
  }
}

h2.force-mobile {
  font-size: 20px;
  line-height: 1.5;
}

h2.force-desktop {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: @darkbrown;

  @media @l {
    font-size: 30px;
    line-height: 1.33;
  }
}

h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: @darkbrown;

  @media @m {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.33;
  }
}

//anchors
a,
a:visited {
  color: @lightgrey;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  font-size: 15px;
  text-decoration: none;
}

button.plain,
a.plain {
  padding: 0;
  border: none;
  background: none;
  outline: none;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: @torch;
  cursor: pointer;
}

button.link,
a.link {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: @torch;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

button.nav,
a.nav,
a.nav:visited {
  background-color: transparent;
  border: none;
  height: 40px;
  padding: 10.5px 16px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: @white;
  text-transform: uppercase;
  cursor: pointer;
}

button.nav.active,
a.nav.active {
  border-radius: 20px;
  background-color: @black;
}

//buttons
button.primary,
a.primary {
  border: none;
  display: inline-block;
  padding: 24px;
  border-radius: 32px;
  background-color: @torch;
  text-transform: uppercase;

  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: @pampas;
  cursor: pointer;
}

button.primary:hover,
a.primary:hover {
  background-color: @lightred;
}

button.primary:active,
a.primary:active {
  background-color: @deepred;
}

button.primary:disabled {
  background-color: @westar;
}

button.secondary,
a.secondary {
  border: none;
  display: inline-block;
  padding: 24px;
  border-radius: 32px;
  background-color: @darkbrown;
  text-transform: uppercase;

  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: @pampas;
  cursor: pointer;
}

button.secondary:hover,
a.secondary:hover {
  background-color: @hovergrey;
}

button.secondary:active,
a.secondary:active {
  background-color: @black;
}

button.tertiary,
a.tertiary {
  display: inline-block;
  background-color: transparent;
  padding: 24px;
  border-radius: 32px;
  border: solid 1px @torch;
  text-transform: uppercase;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: @torch;
  cursor: pointer;
}

button.slim,
a.slim {
  @media @s-strict {
    font-size: 14px;
    padding: 12px 16px;
    line-height: 1;
  }

  @media @m-strict {
    font-size: 14px;
    padding: 12px 16px;
    line-height: 1;
  }
}

button.tertiary:hover,
a.tertiary:hover {
  background-color: @pampas;
}

button.tertiary:active,
a.tertiary:active {
  border-color: @deepred;
  color: @deepred;
}

//breadcrumbs
.breadcrumbLink {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: right;
  color: @torch;
}

.breadcrumbCurrent {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: right;
  color: @darkyellow;
}

//form
input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  outline: none;
  border: solid 1px @light-border;
  background-color: @white;
  padding: 14px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.checkbox {
  width: 100%;
  position: relative;
}

.checkbox input[type="checkbox"] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;
}

.checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 0px;
  width: 22px;
  height: 22px;
  transition: transform 0s ease;
  border-radius: 3px;
  border: 1px solid @light-border;
  background-color: @white;
}

.checkbox label:after {
  content: "";
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid @darkbrown;
  border-left: 2px solid @darkbrown;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0s;
  will-change: transform;
  position: absolute;
  top: 7px;
  left: 6px;
}

.checkbox input[type="checkbox"]:checked ~ label::before {
  color: @darkbrown;
}

.checkbox input[type="checkbox"]:checked ~ label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.checkbox label {
  min-height: 27px;
  display: block;
  padding-left: 40px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.checkbox label span {
  position: relative;
  top: -3px;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline: 0;
}

.radio {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radiocheck {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: @white;
  border: 1px solid @shadow;
  border-radius: 50%;
}

.radio:hover input ~ .radiocheck {
  background-color: @white;
}

.radio input:checked ~ .radiocheck {
  background-color: @darkbrown;
}

.radiocheck:after {
  content: "";
  position: absolute;
  display: none;
}

.radio input:checked ~ .radiocheck:after {
  display: block;
}

.radio .radiocheck:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.field-desc {
  font-size: 16px;
}

//spacing
.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-bottom-64 {
  margin-bottom: 64px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-top-24 {
  margin-top: 24px;
}

.no-wrap {
  white-space: nowrap;
}

//components
.shadow-box {
  background-color: @white;
  padding: 16px;
  box-shadow: 0 16px 32px 0 @shadow;
  text-align: center;
}

.shadow-box-small {
  border: 1px solid @light-border;
  background-color: @white;
  box-shadow: 0 4px 8px 0 @shadow;
}

.self-center {
  align-self: center;
}

ul {
  padding-left: 20px;
  list-style-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='5' height='5' viewBox='-1 -1 2 2'><circle r='0.5' /></svg>");
  list-style-position: outside;
  font-size: 5px;
}

ul li {
  padding-left: 21px;
  font-size: 16px;
}

ul li:not(:last-child) {
  margin-bottom: 15px;
}

ul.checkbox-list {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none;
}

ul.checkbox-list li {
  padding: 20px 32px;
  background-color: @white;
  border: 1px solid @westar;
}

ul.checkbox-list.small li {
  padding: 16px 32px;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: @darkbrown;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: @darkbrown;
}

.react-toggle--checked .react-toggle-track {
  background-color: @tory;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: @tory;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: @tory;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px @shadow;
  -moz-box-shadow: 0px 0px 3px 2px @shadow;
  box-shadow: 0px 0px 2px 3px @shadow;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px @shadow;
  -moz-box-shadow: 0px 0px 5px 5px @shadow;
  box-shadow: 0px 0px 5px 5px @shadow;
}

span.icon {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: inline-block;
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;

  @media @l {
    height: 44px;
    width: 44px;
  }
}

span.icon.green {
  background-color: fade(@persian, 10%);
}

span.icon.flamingo {
  background-color: fade(@flamingo, 10%);
}

span.icon.pacific {
  background-color: fade(@pacific, 10%);
}
