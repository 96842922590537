@import "../../global.less";

.participate-head-box {
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  align-items: center;

  @media @m {
    margin: 0px 70px;
  }
}

.participate-head-box-text {
  width: 80%;
  /* max-width: 700px;*/
}
.participate-trial-info-item-icon {
  height: 20px;
  width: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media @l {
    height: 20px;
    width: 22px;
  }
}
.participate-sub-desc {
  margin: 32px 0px;

  @media @m {
    margin: 64px 70px 32px 70px;
  }
}

.participate-journey-wrapper {
  display: flex;
  flex-direction: column-reverse;

  @media @m {
    flex-direction: row;
  }
}

.participate-journey-area {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.participate-journey-area:not(:first-child) {
  margin-bottom: 32px;

  @media @m {
    margin-bottom: 0px;
    margin-left: 24px;
  }
}

.participate-journey-selector {
  background-color: @white;
  border: 1px solid @shadow;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 16px 64px;
  font-size: 16px;
  background-position: left 16px center, right 16px center;
  background-repeat: no-repeat;
  background-size: 44px;
  width: 100%;
}

.participate-regional-map {
  margin-top: 24px;
  width: 100%;
  height: 320px;
}

.participate-study-results {
  margin-top: 24px;
  width: 100%;
}
.GoogleMap-Point {
  background-color: @torch;
  font-size: 16px;
  font-weight: 400;
  color: @white;
  width: fit-content;
  padding: 4px;
  text-align: center;
  border: 2px solid @white;
  border-radius: 100%;
}
.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: @torch;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
