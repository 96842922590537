@import "../../global.less";

.form-field {
  font-size: 15px;
}

.form-field input {
  display: block;
  width: 100%;
  margin-bottom: 8px;
}

.form-field.error input[type="text"],
.form-field.error input[type="password"] {
  border: 1px solid @flamingo;
}

.form-field-error-msg,
.form-field.error .form-field-label {
  color: @flamingo;
}

.form-field-error-msg {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.form-field-error-msg i {
  margin-right: 8px;
}

.password-field {
  position: relative;
}

.password-field input[type="password"],
.password-field input[type="text"] {
  padding-right: 56px;
}

.password-icon {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 10px;
  align-items: center;
  cursor: pointer;
}

.pass-eye {
  display: block;
  background-position: center;
  background-size: cover;
  width: 44px;
  height: 44px;
  color: @black;
}

.pass-eye.hidden {
  color: @westar;
}
