@import "../../global.less";

.study-guide-back-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
}

.study-guide-name {
  margin: 24px 0px;
}

.study-guide-content-area {
  display: flex;
  flex-direction: column;

  @media @m {
    flex-direction: row;
  }
}

.study-guide-content-img {
  width: 223px;
  height: 265px;
  margin: 0px auto 16px auto;

  @media @m {
    margin-right: 32px;
  }
}

.study-guide-content {
  flex: 1;
}

.study-guide-page-wrap {
  display: flex;
  flex-direction: column;
}

.study-guide-next-prev-wrap {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 64px;
}

.study-guide-next-prev-wrap a {
  margin: auto;
  display: block;
  background-repeat: no-repeat !important;
  background-size: 19px !important;
}

.study-guide-next-prev-wrap a.prev {
  background-position: left center;
  padding: 5px 0px 5px 25px;
  flex: 1;
}

.study-guide-next-prev-wrap a.next {
  background-position: right center;
  padding: 5px 25px 5px 0px;
  flex: 1;
}

.study-guide-splitter {
  margin: 0px 16px;
  height: 32px;
  width: 3px;
  background-color: @darkergrey;

  @media @m {
    margin: 0px 32px;
  }
}
