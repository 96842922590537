@import "../../global.less";

.participate-details-headerNS {
  margin-top: 30px;
}

.participate-details-sub-1NS {
  color: @steel;
  font-size: 15px;
  margin-top: 16px;
}

.participate-details-sub-2NS {
  color: @steel;
  font-size: 15px;
  margin-top: 16px;
}

.participate-details-descNS {
  margin-top: 16px;
}

.trial-info-headNS {
  font-size: 15px;
  font-weight: bold;
  margin-top: 16px;
}

.trial-info-wrapNS {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.trial-info-itemNS {
  background-color: fade(@tory, 10%);
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  line-height: 24px;
  margin: 8px 8px 0px 0px;

  @media @l {
    padding: 8px 16px;
    font-size: 15px;
    line-height: 44px;
  }
}

.trial-info-item-iconNS {
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media @l {
    height: 44px;
    width: 44px;
  }
}

.participate-find-outNS {
  margin-top: 65px;
  text-align: center;
}

.participate-find-locationNS {
  margin-top: 24px;
  text-align: center;
}

.participate-find-wrapNS {
  margin-top: 16px;
  @media @m {
    display: flex;
    flex-direction: row;
    margin-top: 64px;
  }
}

.participate-find-itemsNS {
  flex: 1;
  @media @l {
    display: flex;
    flex-direction: column;
    max-width: 450px;
  }
}

.participate-find-inputNS {
  width: 100%;
  background-size: 44px;
  background-position: 16px center;
  background-repeat: no-repeat;
  padding-left: 72px !important;
}

.participate-filter-itemNS {
  border: 1px solid @shadow;
  padding: 16px 32px;
  background-color: @white;
}
.part-details-box {
  margin-top: 45px !important;
}
.participate-filter-itemNS:not(:first-of-type) {
  margin-top: 8px;
}
.part-Contact-Trial-Regist {
  margin-bottom: 16px;
}
.participate-find-itemsNS hr {
  margin: 16px 0px;
}

.participate-result-boxNS .loc-areaNS {
  color: @steel;
}
.part-email {
  color: @black;
  @media @l {
    font-size: 32px;
  }
}
.participate-filter-mapNS {
  flex: 1;
  height: 472px;
  margin-left: 16px;

  @media @l {
    display: flex;
    flex-direction: column;
  }

  @media @xl {
    height: 630px;
  }
}

.participate-filter-trial-site-locationsNS {
  //align-self: flex-end;
  float: right;
  color: @darkyellow;
  @media @m {
    margin-top: 445px;
  }
  @media @l {
    margin-top: 445px;
    float: none;
    display: block;
  }
}

.participate-filter-resultsNS {
  max-height: 700px;
  overflow-y: auto;
}

.Iconsic_phone {
  width: 6%;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  float: left;
  margin-right: 4%;
  @media @m {
    width: 3%;
    height: 25px;
    margin-right: 1%;
  }
  @media @l {
    width: 4%;
    height: 55px;
  }
}
.clinicaltrialscslbe {
  margin-right: 8%;
  font-size: 16px;
  margin-bottom: 10px;
  @media @m {
    float: left;
    margin-bottom: 0px;
  }
  @media @l {
    font-size: 32px;
  }
}

.part-filter-dividerNS {
  margin-top: 48px;
}
.Study-Eligibity-div {
  margin-left: 8%;
  margin-right: 8%;
  width: 84%;
}
.Study-Eligibility-Cr {
  margin-top: 48px;
  margin-bottom: 16px;
}

.part-additional-studiesNS {
  margin-top: 64px;
}

.part-additional-studies-descNS {
  margin-top: 16px;
}
.google-map > div {
  position: absolute !important;
  width: 87% !important;
  height: 437px !important;
  margin-top: 32px !important;
  @media @m {
    width: 53% !important;
    margin-top: 0px !important;
  }
  @media @l {
    width: 54% !important;
  }
  @media @xl {
    width: 57% !important;
  }
  @media @xxl {
    width: 51% !important;
  }
}
