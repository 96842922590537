@import "../../global.less";

#outer-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 375px;
}

#main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1440px;
  height: 100%;
  width: 100%;
  margin: 0px auto 64px auto;
  position: relative;
  z-index: 0;

  @media @m {
    margin-bottom: 88px;
  }
}

#page-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  max-width: 1440px;
}
.Network-Issue {
  color: @steel;
  font-size: 32px;
  margin-top: 200px;
  text-align: center;
  width: 100%;
  @media @l {
    margin-top: 350px;
  }
}
