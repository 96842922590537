@import "../../global.less";

.error-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0px 0px 0px;
  min-height: 200px;
}

.error-content {
  z-index: 100;
  text-align: center;
}
