@import "../../global.less";

ul.pass-requirements {
  list-style-type: none;
  margin: 8px 0px 0px 8px;
  padding: 0;
}

ul.pass-requirements li {
  display: flex;
  align-items: center;
  font-size: 14px;
}

ul.pass-requirements li i {
  color: @light-border;
  margin-right: 5px;
  font-size: 20px;
}

ul.pass-requirements li:not(:last-child) {
  margin-bottom: 8px;
}

ul.pass-requirements li i.valid {
  color: @green;
}

ul.pass-requirements li span {
  font-size: 14px;
}
