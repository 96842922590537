@import "../../global.less";

.location-wrap {
  display: flex;
  flex-direction: column;

  @media @m {
    flex-direction: row;
    margin-bottom: 48px;
  }
}

.location-map {
  margin-top: 16px;
  height: 222px;
  width: 100%;
  /*align-self: center;*/

  @media @m {
    height: 308px;
    width: 308px;
    margin-right: 32px;
  }

  @media @xl {
    height: 412px;
    width: 412px;
  }

  @media @xxl {
    height: 465px;
    width: 465px;
  }
}

.location-details-sub {
  margin-top: 16px;
}

.location-address {
  margin-top: 8px;
}

.directions {
  margin-top: 24px;
  margin-bottom: 32px;
  color: @deepred !important;
  text-decoration: underline;
}

.contact-details {
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  @media @m {
    flex-direction: row;
  }
}

.contact-details > div {
  @media @m {
    flex: 1;
  }
}

.contact-details span {
  font-size: 15px;
}

.contact-details .email {
  margin-bottom: 24px;
}
.CSL-Trial-part-email {
  color: @black;
}
