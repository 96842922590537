@import "../../global.less";

@bottom-horizontal-margin: 67px;

footer {
  background-color: @darkbrown;
  color: @lightgrey;
}

footer .footerbox {
  display: flex;
  padding: 32px 80px 0px 80px;
  flex-direction: column;
  @media @l {
    flex-direction: row;
  }
}

footer .footerbox .logo {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  @media @l {
    margin-bottom: 0px;
    justify-content: flex-start;
  }
}

footer .footerbox .logo img {
  width: 195px;
}

.footerbox .social {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  @media @l {
    justify-content: flex-end;
  }
}

footer .footerbox .social img {
  padding: 0px 8px;
  height: 44px;

  @media @l {
    padding: 0px 16px;
  }
}

footer .footerbox .social img:last-child {
  padding-right: 0px;
}

footer .divider {
  height: 1px;
  background-color: @steel;
  margin: 32px 8px;

  @media @l {
    margin: 32px 80px;
  }
}

footer .termsbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 15px 32px 15px;

  @media @l {
    padding: 0px 80px 32px 80px;
    flex-direction: row;
  }

  @media @l-strict {
    padding: 0px 24px 32px 24px;
  }
}

footer .termsbox .copyright {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  vertical-align: middle;
  margin-top: 24px;

  @media @l {
    margin-top: 0px;
  }

  @media @xxl {
    margin-right: @bottom-horizontal-margin;
  }
}

footer .termsbox .links {
  display: flex;
  flex: 1;
  flex-direction: column;

  @media @l {
    flex-direction: row;
  }

  @media @xxl {
    margin-left: @bottom-horizontal-margin;
  }
}

footer .termsbox .links a {
  margin-bottom: 24px;
  @media @l {
    margin-right: 32px;
    margin-bottom: 0px;
  }
  text-align: center;
  vertical-align: middle;
}
