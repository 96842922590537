@import "../../global.less";

.csl-modal {
  display: flex;
  flex-direction: column;
  background-color: @white;
  outline: none;
  box-shadow: 0 16px 32px 0 @shadow;
  max-height: 85%;
  min-height: 200px;
}

.csl-modal-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
}

.csl-modal-close-bar {
  padding: 16px 16px 0px 16px;

  @media @l {
    padding: 32px 32px 0px 32px;
  }
}

.csl-modal-close-bar img {
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.csl-modal-content {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  margin-bottom: 32px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media @l {
    padding: 0px 108px;
  }
}

.csl-modal-video {
  width: 100%;
  height: 300px;

  @media @m {
    height: 300px;
    width: 500px;
  }

  @media @l {
    height: 500px;
    width: 700px;
  }
}
