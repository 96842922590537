@import "../../global.less";

.Link-Title {
  width: 80%;
  /*height: 20px;*/
  margin: 0px 0px 1.2px 8px;
  padding: 14.8px 5% 14.8px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: @darkbrown !important;
  float: left;

  @media @m {
    width: 88%;
  }

  @media @l {
    width: 92%;
  }

  @media @xl {
    width: 87%;
  }
}

.Oval {
  width: 21px;
  height: 21px;
  margin-top: 13px;
  background-size: 100%;
  justify-self: flex-end;
  display: inline-block;
}

.Rectangle-LinkList {
  display: flex;
  width: 100%;
  margin: 0 0px 0px;
  /* height: 50px;*/
  border-bottom: 1px #e2dfda;
  float: right;
  border-bottom-style: double;
}

.Combined-Shape {
  width: 19px;
  height: 19px;
  margin-top: 14px;
  background-color: var(--fc-1921-torch);
  background-size: 19px 19px;
  background-image: url("../../assets/icons/ic_circle-arrow-right Torch.png");
}
