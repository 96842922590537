@import "../../global.less";

.Guides---Glossary---S {
  width: 91%;
  margin-left: 4.5%;
  margin-right: 4.5%;
  background-color: @lightgrey;

  @media @m {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  @media @xl {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  @media @xxl {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}
.Div-BackButton-Glossary {
  width: 100%;
  top: 360px;
  position: fixed;
  background-color: @lightgrey;
  @media @l {
    display: block;
  }
}
.Div-BackButton-Glossary > div {
  @media @l {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
}
.Div-Glossary {
  display: none;
  @media @l {
    display: block;
  }
}
.ScrolApplied {
  top: -2px !important;
}
.Clinical-Trial-Frequ-Glossary {
  width: 100%;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  margin-top: 28px;
  letter-spacing: normal;
  color: @darkbrown;
  @media @m {
    width: 100%;
    font-size: 30px;
  }

  @media @l {
    width: 100%;
    font-size: 60px;
    float: left;
    margin-bottom: 32px;
  }
}
.ScrollMargin {
  top: 300px;
  position: relative;
}
.glossary-Sub-Catagory {
  width: 100%;
  height: 40px;
  margin: 0 0 8px;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: @steel;
  @media @xl {
    float: left;
    width: 6%;
    margin-left: 2%;
  }
  @media @xxl {
    width: 8%;
  }
}
.Accepts-healthy-volu {
  width: 100%;
  margin: 8px 0 0;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: @darkbrown;
}
.Accepts-healthy-volu-text-style-1 {
  font-weight: bold;
}
.text-style-0 {
  color: @darkbrown;
  float: left;
}
.mr-5 {
  margin-right: 1%;
  margin-left: 1%;
  @media @m {
    margin-right: 0.25%;
    margin-left: 0.25%;
  }
}
.text-style-2 {
  color: @torch !important;

  text-decoration: underline !important;
  text-decoration-color: @torch!important;
  font-size: 16px !important;
  cursor: pointer;
}
.text-style-3 {
  color: @darkbrown;
}
.margin-bottom-32-Glossary {
  margin-bottom: 32px;
  width: 99%;
  @media @xl {
    width: 85%;
    float: left;
    margin-right: 7%;
  }
  @media @xxl {
    width: 83%;
  }
}
.Clinical-Trial-Frequ-Right {
  @media @xxl {
    width: 100%;
  }
}
.Rectangle-Glossary {
  width: auto;
  margin-right: 0.5%;
  padding: 14px 5px;
  border: solid 1px @westar;
  background-color: @white;
  float: left;
  margin-bottom: 32px;
  margin-top: 32px;
  cursor: pointer;
  @media @l {
    margin-right: 9px;
    padding: 14px 7px;
  }
  @media @xl {
    margin-right: 22px;
  }
}
.Clinical-Trial-Glossary-Alph {
  width: 100%;
  float: left;
  margin-top: 12px;
}
.CLinical-Trial-Glossary-Search {
  border: solid 1px @westar;
  background-color: @white;
  float: left;
  @media @xxl {
    width: 35%;
    height: 55px;
    margin-left: 2%;
    padding: 0px 16px;
    margin-bottom: 32px;
  }
}
.Entrted-text {
  width: 90%;
  height: 37px;
  border: none;
  margin-left: 7%;
  padding: 8px 16px;
  font-size: 18px;
  color: @steel;
}
.Search-Button {
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: absolute;
  background-image: url("../../assets/icons/ic_search.png");
  margin: 9px 0px;
  @media @xxl {
    height: 35px;
    width: 2%;
  }
}
.Alph-light {
  background-color: @lightgrey;
  border: solid 1px @westar;
  cursor: pointer;
}
.mb-Glossary-0 {
  margin-bottom: 0px;
}
