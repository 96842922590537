@import "../../global.less";

.participate-results-header {
  margin-top: 32px;
}

.participate-filter {
  margin-top: 32px;
  padding: 20px 20px 20px 60px;
  background-position: 4px center;
  background-size: 40%;
  background-repeat: no-repeat;
  background-color: @white;
  border: 1px solid @shadow;
  cursor: pointer;
}

.participate-results-wrap {
  display: flex;
  flex-direction: column;

  @media @l {
    flex-direction: row;
  }
}

.participate-results {
  margin-top: 32px;

  @media @l {
    flex: 1;
    margin-right: 16px;
    width: 470px;
  }
}

.participate-result-item {
  color: @black !important;
  display: block;
  background-color: @white;
  padding: 16px;
  border: 1px solid @shadow;
  cursor: pointer;

  @media @m {
    padding: 24px;
  }
}

.participate-result-item:not(:first-child) {
  margin-top: 16px;
}

.participate-result-item .sub-title {
  font-size: 15px;
  color: @steel;
}

.participate-result-item .title {
  color: @torch;
  margin-top: 16px;
}

.participate-result-item .desc {
  margin-top: 8px;
}

.participate-result-item .filter-area {
  display: flex;
  margin-top: 8px;
  flex-wrap: wrap;

  @media @l {
    margin-right: 16px;
  }
}

.participate-result-item .filter {
  background-color: fade(@tory, 10%);
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  margin: 8px 8px 0px 0px;
}

.participate-search-map-wrap {
  display: flex;
  flex-direction: column;

  @media @l {
    flex: 1;
  }
}

.participate-search-map {
  margin-top: 32px;
  width: 100%;
  height: 230px;

  @media @l {
    height: 472px;
  }

  @media @xl {
    height: 630px;
  }

  @media @xxl {
    height: 710px;
  }
}

.participate-search-map-loc {
  margin-top: 8px;
  align-self: flex-end;
  color: @darkyellow;
}

//modal
.participate-filter-box {
  display: block;
  width: 100%;
  padding-bottom: 24px;
}
.participate-filter-head {
  font-size: 15px;
  font-weight: bold;
}

.participate-filter-head:not(:first-child) {
  margin-top: 24px;
}

.participate-filters {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.participate-filter-button-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
}

.participate-filters button {
  background-color: @white;
  padding: 8px;
  border: 1px solid @shadow;
  font-size: 16px;
  line-height: 35px;
}

.participate-filters button.active {
  background-color: @torch;
  border: 1px solid @deepred;
  color: @white;
}

.participate-filters button div {
  background-position: center;
  background-size: contain;
  height: 35px;
  width: 35px;
}

.participate-filter-button-show-hide {
  margin-top: 8px;
}

.participate-loc-filter {
  width: 100%;
  background-position: 4px center;
  background-size: 44px;
  padding-left: 50px !important;
  background-repeat: no-repeat;
}

.participate-filter-box hr {
  margin: 32px 0px;
}

.participate-filter-update {
  text-align: center;
}
