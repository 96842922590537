@import "../../global.less";

.info-icon {
  display: inline-block;
  margin-top: 0px;
  vertical-align: middle;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.popover-box {
  background-color: @white;
  padding: 20px;
  border-radius: 4px;
}

.popover-content {
  margin-bottom: 16px;
  max-width: 300px;
  text-align: left;
}

.popover-content hr {
  margin: 8px 0px;
}

.popover-title {
  font-size: 17px;
  font-weight: bold;
}

.popover-close {
  display: block;
}

.popover-info-ico {
  vertical-align: text-top;
}

h1 .popover-info-ico,
h2 .popover-info-ico,
h3 .popover-info-ico,
h4 .popover-info-ico {
  @media @l {
    margin-top: 5px;
  }
}
