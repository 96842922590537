@import "../../global.less";

button.arrow,
button.arrow:focus,
button.arrow:active {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  width: 24px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.detail-wrap {
  display: flex;
  flex-direction: column;

  @media @l {
    flex-direction: row;
    width: 100%;
  }
}

.detail-info {
  display: flex;
  flex-direction: column;
  flex: 0.75;
}

.detail-info hr {
  margin-top: 16px;
}

.detail-info-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.current-step {
  color: @rollingstone;
}

.step-index {
  color: @purple;
  font-weight: 600;
}

.treatment-info {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  @media @l {
    flex: 1;
    margin-left: 48px;
  }
}

.detail-nav {
  display: flex;
  width: 100px;
  margin-bottom: 16px;
  justify-content: space-between;
}

.treatment-info hr {
  margin: 24px 0px;
}

.treatment-item-row:first-of-type {
  @media @l {
    margin-top: 16px;
  }
}

.treatment-item-row {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  margin-top: 8px;
}

.treatment-item-row span.icon {
  width: 24px;
  height: 24px;
}

.treatment-icon {
  margin-right: 8px;
  display: inline-block;
}

.item-detail-description {
  color: @rollingstone;
  font-size: 14px;
}

.add-dates {
  align-self: flex-end;
}

.check-wrap {
  margin-top: 25px;
  border: 1px solid @shadow;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-self: flex-start;
}

.check-wrap .checkbox {
  width: auto;
}
