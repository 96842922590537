@import "../../global.less";

.Guides---FAQ---S {
  width: 91%;
  margin-left: 4.5%;
  margin-right: 4.5%;
  background-color: @lightgrey;

  @media @m {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  @media @xl {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  @media @xxl {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}
.Div-BackButton {
  width: 100%;
}
.Clinical-Trial-Frequ {
  width: 100%;
  margin-bottom: 16px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: @darkbrown;
  margin-bottom: 24px;
  @media @m {
    width: 100%;
    font-size: 30px;
  }

  @media @l {
    width: 100%;
    font-size: 60px;
  }
}
.Rectangle-FQA {
  width: 92%;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 16px;
  padding: 24px 4%;
  border: solid 1px @light-border;
  background-color: @white;
  @media @m {
    padding: 16px 4%;
  }
  @media @xxl {
    width: 71%;
    margin-left: 8.5%;
    padding: 24px 2%;
  }
}

.Section-Title-FQA {
  width: 78%;
  cursor: pointer;
  margin: 10.8px 4% 9.2px 2%;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: @darkbrown;
  float: left;
  @media @m {
    width: 93%;
    margin: 10.8px 0% 9.2px 2%;
  }
}
.Path-FQA {
  width: 4%;
  height: 24px;
  padding: 9px 6px;
  margin-right: 2%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  float: right;
  @media @m {
    width: 3%;
    margin-right: 0%;
  }
}
.Path-FQA-right {
  background-image: url("../../assets/icons/ic_caret-right.png");
}
.Path-FQA-down {
  background-image: url("../../assets/icons/ic_caret-down.png");
}
.Nulla-vitae-elit-lib {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: @darkbrown;
  margin-left: 2%;
  float: left;
  padding-top: 8px;
  border-top: thick;
  border-top-color: @light-border;
  border-top-style: solid;
  border-top-width: thin;
}
.display-block {
  display: block;
}
.display-none {
  display: none;
}
.mb-0 {
  margin-bottom: 0px;
}
.Answer-ul {
  font-size: 16px;
  padding-left: 0px;
}
.Answer-li {
  list-style-type: decimal;
}
.TextHightLight {
  font-weight: bold;
}
.BreakLine-Div {
  margin-bottom: 8px;
  margin-top: 8px;
}
