@import "../../global.less";

.quickstart-location-head {
  margin-top: 32px;
}

.quickstart-location-select {
  border: 1px solid @shadow;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 32px 64px;
  margin-top: 16px;
  background-position: left 16px center, right 16px center;
  background-repeat: no-repeat;
  background-size: 44px;
  width: 100%;
}

.quickstart-location-results {
  margin-top: 32px;
}

.quickstart-location-results .quickstart-location-item {
  padding: 16px 20px;
  border: 1px solid @shadow;
  background-color: @white;
  margin-top: 16px;
}

.quickstart-create-profile-head {
  margin-top: 32px;
}
