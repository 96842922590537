@import "../../global.less";

.message-wrap {
  display: flex;
  flex-direction: column;

  @media @l {
    flex-direction: row;
  }
}

.message-wrap .message-block {
  @media @l {
    width: 309px;
  }

  @media @xl {
    width: 304px;
  }
}

.message-area {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  @media @l {
    flex: 1;
    margin-top: 0px;
    margin-left: 32px;
  }
}

.del-wrap {
  margin-top: 48px;
  text-align: center;
}
