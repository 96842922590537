@import "../../global.less";

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-area {
  margin-top: 32px;
  width: 100%;

  @media @m {
    width: 411px;
  }
}

.login-area h1 {
  text-align: center;
  margin-bottom: 24px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.forgot-password {
  margin-top: 32px;
  text-align: right;
}

.sign-in-options {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
}

.login-error-message {
  margin: 0px auto 16px auto;
  color: @torch;
}
