@import "../../global.less";
@import "../../components/learn-guide/glossary.less";
.Product-Pipeline {
  font-size: 30px;
  font-weight: 300;
  width: 100%;
  margin-top: 32px;
  font-family: Montserrat;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: @darkbrown;
  @media @m {
    width: 100%;
    font-size: 30px;
  }
  @media @l {
    font-size: 60px;
  }
}
.CSL-Behring-is-a-glo {
  width: 100%;
  margin: 16px 0px 32px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: @darkbrown;

  @media @m {
    width: 100%;
    margin-bottom: 40px;
  }

  @media @l {
    width: 100%;
    margin: 16px 0px 48px 0px;
  }

  @media @xl {
    margin: 16px 0px 16px 0px;
    width: 82%;
  }

  @media @xxl {
    width: 82%;
    margin: 16px 0px 16px 0px;
  }
}
.Core-Capabilities {
  font-size: 20px;
  @media @l {
    font-size: 40px;
  }
}
.Rectangle-FR {
  width: fit-content;
  margin: 16px 0px 16px 0px;
  padding: 14px 8px;
  box-shadow: inset 0 0 4px 0 rgba(41, 38, 30, 0.25);
  border: solid 1px rgba(41, 38, 30, 0.1);
  background-color: @torch;
  color: @white;
  font-size: 16px;
  float: left;
  @media @m {
    margin-top: 24px;
  }
  cursor: pointer;
}
.Filter-Name {
  color: @darkbrown;
  background-color: @white;
  border: solid 1px @light-border;
  box-shadow: none;
}
.Rectangle-Select {
  width: 92%;
  margin: 0px 0px 0px;
  padding: 10px 8px 10px 16px;
  border-radius: 32px;
  border: solid 1px @torch;
  font-size: 14px;
  color: @torch;
  float: left;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  background-color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-size: 44px;
  background-position: right 10px center;
  background-repeat: no-repeat;

  @media @m {
    width: 36%;
    margin-left: 32px;
    margin-top: 35px;
    padding-top: 8px;
  }
  @media @l {
    margin-left: 32px;
    margin-top: 29px;
    padding: 16px 0px 16px 22px;
    font-size: 16px;
  }
}

.Rectangle-Select:focus {
  background-image: url("../../assets/icons/ic_caret-down.png") !important;
}

.Path-RD-down {
  width: 10%;
  height: 27px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  float: right;
  background-image: url("../../assets/icons/ic_caret-down.png");
  @media @l {
    width: 5% !important;
    height: 32px;
  }
}
.RDCursor {
  cursor: pointer;
}
.Path-Rd-Right {
  background-image: url("../../assets/icons/ic_caret-right.png");
}
.Rectangle-Section-Title .Path-Rd-Right {
  @media @m {
    width: 5% !important;
    height: 27px;
  }
}
.Research-Pre-Clini {
  color: @darkbrown;
  float: left;
  @media @l {
    font-size: 30px;
  }
}
.ImageDiv {
  margin-bottom: 19px;
  float: left;
  width: 100%;
  margin-top: 36px;
}
.ImageSUbDIv {
  float: left;
  margin-left: 4%;
  width: 75%;
  padding-left: 4%;
  @media @m {
    margin-left: 2%;
    width: 96%;
    padding-left: 2%;
  }
}
.Bitmap-ItemImage {
  width: 12%;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 4%;
  float: left;
  @media @m {
    width: 5%;
    margin-right: 2%;
  }
  @media @l {
    width: 5%;
    margin-right: 7px;
    height: 56px;
  }
}
.RDPipelineSub {
  width: 100%;
  @media @xl {
    width: 82%;
    margin: 0 9%;
  }
}
.HematologyDiv {
  width: 84%;
  font-size: 20px;
  font-weight: 500;
  color: @darkbrown;
  float: left;
  margin-top: 6px;
  @media @l {
    font-size: 30px;
    font-weight: 300;
    height: 40px;
    margin-top: 0px;
  }
}
.Rectangle-ItemLine {
  width: 1%;
  height: 78px;
  float: left;
  margin-left: 5%;
  margin-right: 4%;
  @media @m {
    width: 0.5%;
    margin-left: 2%;
    margin-right: 1%;
  }
  @media @l {
    width: 0.35%;
    margin-left: 2.25%;
    margin-right: 2%;
  }
}
.Rectangle-Section-Title {
  width: 94%;
  padding: 24px 32px;
  border: solid 1px @light-border;
  background-color: @white;
  float: left;
  @media @m {
    width: 88%;
  }
  @media @l {
    margin-left: 0%;
    width: 90%;
  }
}
.Section-TitleRDDiv {
  width: 90%;
  float: left;
  @media @m {
    width: 95%;
  }
}
.answerRD {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: @darkbrown;
  float: left;
  padding-top: 12px;
  width: 100%;
  margin-top: 12px;
  border-top: thick;
  border-top-color: @light-border;
  border-top-style: solid;
  border-top-width: thin;
}
.display-blockRD {
  display: block;
}
.display-noneRD {
  display: none;
}
.darkpurple {
  border-left: solid 5px @darkpurple;
}
.OutlicensedColor {
  border-left: solid 5px @steel;
}
.RespiratoryColor {
  border-left: solid 5px @flamingo;
}
.TransplantColor {
  border-left: solid 5px @tory;
}
.Cardiovascular-and-M {
  border-left: solid 5px @greenyellow;
}
.ImmunologyColor {
  border-left: solid 5px @pacific;
}
.CovidColor {
  border-left: solid 5px @lightpurple;
}
.SectionMargin {
  margin-top: 30px;
}
.FilterApplied {
  display: none;
}
.No-Products {
  width: 100%;
  height: 28px;
  margin: 16px 0px 0px 0;
  font-family: Montserrat;
  font-size: 16px;
  float: left;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: @rollingstone;
}
.SectionMarginBtm {
  margin-bottom: 16px;
}
