@import "../../global.less";

.profile-subhead {
  margin-top: 16px;
}

.section-header {
  margin-top: 24px;
  margin-bottom: 14px;
}

.patient-field {
  margin-top: 8px;
}
.email-field {
  margin-top: 24px;
}

.name-fields {
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  @media @m {
    flex-direction: row;
    margin-bottom: 24px;
  }
}

.name-fields .form-field:first-child {
  @media @m {
    margin-bottom: 16px;
  }
}

.name-fields .form-field:first-child {
  @media @m {
    margin-right: 16px;
  }
}

.name-fields .form-field {
  margin-bottom: 24px;

  @media @m {
    flex: 1;
    margin-bottom: 0px;
  }
}

.pref-area {
  display: flex;
  flex-direction: row;
}

.pref-area .pref-fields:first-child {
  @media @m {
    margin-right: 16px;
  }
}

.pref-fields {
  flex: 1;
}

.pref-fields.filler {
  display: none;

  @media @m {
    display: block;
  }
}

.unsub-comm {
  margin-top: 19px;
}

.opt {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.opt:not(:last-child) {
  margin-bottom: 24px;
}

.privacy-rights {
  margin-top: 16px;
}

.profile-save-buttons {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  @media @m {
    margin: 64px auto 0px auto;
    flex-direction: row;
    justify-content: space-between;
    width: 580px;
  }

  @media @l {
    width: 700px;
  }
}

.profile-save-buttons button {
  width: 100%;
}

.profile-save-buttons button:first-child {
  margin-bottom: 40px;

  @media @m {
    margin-bottom: 0px;
    margin-right: 16px;
  }
}
.profile-upBtn {
  width: 237px !important;
}
