@import "../../global.less";

.Guides---Patient---S {
  width: 91%;
  margin-left: 4.5%;
  margin-right: 4.5%;
  background-color: @lightgrey;

  @media @m {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  @media @xl {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  @media @xxl {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}
.Clinical-Trial-Frequ-Patient {
  width: 50%;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  margin-top: 28px;
  letter-spacing: normal;
  color: @darkbrown;

  @media @s {
    width: 100%;
  }


  @media @m {
    width: 100%;
    font-size: 30px;
  }

  @media @l {
    width: 100%;
    font-size: 60px;
    float: left;
    margin-bottom: 32px;
  }
  @media @xxl {
    margin-bottom: 8px;
  }
}
.Patient-Support-ImageDIv {
  width: 100%;

  @media @m {
    float: left;
  }
}
.Bitmap-Patient-Support {
  width: 100%;
  height: 193px;
  margin: 16px 0px 8px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media @m {
    width: 96%;
    height: 148px;
    float: left;
    margin: 0px 2% 16px 0px;
  }

  @media @l {
    width: 96%;
    height: 185px;
    margin: 0px 4% 8px 0px;
  }

  @media @xl {
    width: 94%;
    height: 233px;
    margin: 8px 6% 8px 0px;
  }
  @media @xxl {
    width: 98%;
    height: 255px;
    margin: 8px 2% 8px 0px;
  }
}

.Emily-Clinical-Trial {
  width: 100%;
  margin: 0px 0px 33px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: @darkyellow;
  text-align: right;
  @media @m {
    width: 96%;
    margin: 8px 2% 48px 0px;
  }

  @media @l {
    width: 96%;
    margin: 8px 4% 48px 0px;
  }

  @media @xl {
    width: 94%;
    height: 136px;
    margin-right: 6%;
    margin-top: 8px;
  }

  @media @xxl {
    width: 97%;
    height: 136px;
    margin-right: 3%;
    margin-top: 8px;
  }
}

.link-padding{
  padding:20px 0px;
}

.Youve-likely-had-a {
  width: 100%;
  margin: 16px 0px 32px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: @darkbrown;

  @media @m {
    width: 100%;
    margin-bottom: 32px;
    margin-top: 0px;
  }

  @media @l {
    width: 100%;
    height: 54px;
    margin: 16px 0px 48px 0px;
  }

  @media @xl {
    margin: 16px 0px 16px 0px;
    width: 80%;
  }

  @media @xxl {
    width: 74%;
    margin: 16px 0px 16px 0px;
  }
}
.Youve-likely-had-a span {
  width: 100%;
  float: left;
  margin-bottom: 32px;
}
.Kidney-Disease-Suppo {
  font-size: 20px;
  @media @l {
    font-size: 30px;
    margin-bottom: 8px;
  }
}
.nationalSupportImages {
  height: 40px;
  width: 188px;
  background-size: contain;
  @media @m {
    width: 188px;
    margin-top: 12px;
    margin-bottom: 8px;
  }
}
.American-Kidney-Fund {
  color: @torch !important;
  font-size: 16px;
  width: 100%;
  float: left;

}

.American-text-Fund {
  color: black;
  font-weight: bold !important;
  // font-weight: semi;
  width: 100%;
}

.P{
   font-family: Montserrat semi-bold;
}



.Bitmap-National {
  height: 140px;
  width: 140px;
  margin-top: 0px;
}

.wig_dev {

  @media @s {
    width: 85%;
    float: left;
    padding: 30px 20px;
  }
  @media @m{

    width: 43.7%;
    height: 300px;
    float: left;
    padding: 20px;

  }
  @media @l {
    width: 30.2%;
    height:320px;
    float: left;
    // background-color: green;

    padding:20px;
  }
  @media @xl {
    width: 17.2%;
    float: left;
    padding:13px 94px 13px 7px;
    }

  // @media @xxl {
  //   width: 22.2%;
  //   // background-color: red;
  //   float: left;
  //   padding:20px;
  // }
}



.marginPS {
  margin-bottom: 16px !important;
}
.Eastern-float {
  float: left;
  cursor: pointer;
}
.Patient-LeftDiv {
  @media @m {
    width: 38%;
    float: left;
    margin-right: 2%;
    margin-top: 16px;
  }
  @media @l {
    width: 35%;
    float: left;
    margin-right: 2%;
    margin-top: 16px;
  }
  @media @xl {
    width: 34%;
    margin-right: 2%;
    margin-bottom: 32px;
  }
  @media @xxl {
    width: 33%;
  }
}
.Patient-RightDiv {
  @media @m {
    width: 50%;
    float: left;
  }
  @media @l {
    width: 63%;
  }
  @media @xl {
    width: 64%;
  }
  @media @xxl {
    width: 57%;
  }
}
.Eastern-title {
  @media @m {
    float: left;
  }
}
.LinkMarginBt {
  margin-bottom: 4px;
}
.Kidney-Div-Font {
  @media @l {
    font-size: 30px;
  }
}
.Bottom-left {
  @media @l {
    width: 50%;
    float: left;
  }
}


.National-title {
  margin-top: 10px;
}

.Rectanglediv {
  width: 306px;
  height: 122px;
  margin: 0 0 16px;
    padding: 30px 40px 29px 39px;
  background-color:white;

  @media @s{
    width: 77%;
    height: 100px;

  }

  @media @m {
    width: 80%;
    height: 100px
;

  }
  @media @l {
    width: 84%;
    height: 100px;

  }

  @media @xl {
    width: 100%;
    height: 78px;
    // background-color: black;

  }

}


.Rectanglediv1 {
  width: 306px;
  height: 122px;
  margin: 0 0 16px;
    // padding: 30px 40px 29px 39px;
  background-color:white;

  @media @s{
    width: 100%;
    height: 140px;

  }

  @media @m {
    // width: 84%;
    width: 306px;
    height: 161px;

  }

  @media @xl {
    // width: 84%;
    width: 268px;
    height: 139px;

  }
}


.Rectangle1 {
  width: 306px;
  height: 122px;
  margin: 0 0 16px;
  padding: 14px 40px 45px 39px; 
  background-color:white;

  @media @s{
    width: 77%;
    height: 100px;

  }

  @media @m {
    width: 80%;
    height: 100px;

  }
  @media @l {
    width: 84%;
    height: 100px;

  }

  @media @xl {
    width: 85%;
    height: 78px;

  }
}

.image005{
  width: 225px;
  height: 63px;
  margin: 16px 0px 8px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media @s {
    width: 225px;
    height: 63px;
    float: left;
    margin: 16px 0px 8px 0px;
  }


  @media @m {
    width: 119%;
    height: 97px;
    float: left;
    margin: -3px 0% 0px -13px;
  }

  @media @l {
    width: 99%;
    height: 82px;
    padding: 2px;
  }

  @media @xl {
    width: 105%;
    height: 53px;
    padding: 17px 17px;
  }

}


.image010{
  width: 225px;
  height: 63px;
  margin: 16px 0px 8px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media @s {
    width: 103%;
    height: 140px;
    margin: 0px;
  }

  @media @m {
    width: 108%;
    height: 159px;
    margin: 0px 2% 16px 0px;
  }

  @media @l {
    width: 132%;
    height: 159px;
  }

  @media @xl {
    width: 118%;
    height: 136px;
  }

}




.image001{
  width: 225px;
  height: 63px;
  margin: 16px 0px 8px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;



  @media @l {
    width: 131px;
    height: 64px;
    padding: 2px;
  }


}
.image002{
  width: 225px;
  height: 63px;
  margin: 16px 0px 8px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


  @media @l {
    width: 131px;
    height: 64px;
    padding: 2px;
  }

}

.image003{
  width: 225px;
  height: 63px;
  margin: 16px 0px 8px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media @s {
    width: 222px;
    height: 108px;
    margin: -6px auto;


  }

  @media @m {
    width: 227px;
    height: 111px;
    margin: -10px auto;
 
   }

   @media @xl {
    width: 227px;
    height: 111px;
    margin: -15px auto;
 
   }

}

.image004{
  width: 225px;
  height: 63px;
  margin: 16px 0px 8px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media @s {
    margin: 19px auto;
    width: 243px;
    }

  @media @m {
    margin: 16px auto;
    width: 242px;

    }

  @media @l {
    width: 244px;
    margin: 18px auto;


  }

  @media @xl {
    width: 244px;
    margin: 5px 2px;
  }

}

.image006{
  width: 225px;
  height: 64px;
  margin: 16px 0px 8px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media @m {
 
    margin: 19px auto;
    }

  @media @l {
    width: 225px;
    margin: 19px auto

  }

  @media @xl {
    width: 225px;
    margin: 6px auto;
  }

}

.image009{
  width: 225px;
  height: 64px;
  margin: 16px 0px 8px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media @s {
    width: 256px;
    height: 60px;
    margin: 19px -16px;
    
  }

  @media @m {
    width: 249px;
    height: 61px;
    margin: 19px auto;
  }

  @media @l {
    width: 245px;
    margin: 19px auto;

  }

  @media @xl {
    width: 245px;
    margin: 9px -3px;

  }
}


.image007{
  width: 225px;
  height: 63px;
  margin: 16px 0px 8px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media @s {
 
    margin: auto ;
    height: 133px;

  }
  
  @media @m {
 
  margin: auto ;
  height: 133px;
  
  }

  @media @l {
 
  margin: auto ;
  height: 133px;
    
  }

  @media @xl {
 
    margin: -13px -14px ;
    height: 133px;
      
    }

}

.image008{
  width: 120px;
  height: 63px;
  margin: 16px 0px 8px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


  @media @s {
    margin: -16px auto;
    height: 119px;

  }

  @media @m {
    margin: -7px auto;
    height: 120px;

  }


  @media @l {
 
    margin: -17px auto;
    width: 119px;

  
  }

  @media @xl {
 
    margin: -27px auto ;
    height: 133px;
    width:133px;
  }


      

}

p{
  color:#29261E;

}

.External-Resources {
  width: 707px;
  height: 40px;
  margin: 0 282px 33px 3px;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--29261-e-zeus);
}

// .title {
//   width: 100%;
//   height: 28px;
//   margin: 16px 192px 8px 0;
//   font-family: Montserrat;
//   font-size: 16px;
//   font-weight: 600;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.7;
//   letter-spacing: normal;
//   color: var(--29261-e-zeus);
// }

.Disease-Information {
  width: 162px;
  height: 64px;
  margin: 8px 144px 0 0;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--fc-1921-torch);
}