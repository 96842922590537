@import "../../global.less";

//calendar start

.react-calendar {
  width: auto;
  border: 1px solid @shadow !important;
  background-color: @white;
  padding: 16px;
  box-shadow: 0 4px 8px 0 @shadow;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.7 !important;
  letter-spacing: normal !important;
}

.react-calendar__tile {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  color: @darkbrown !important;

  @media @l {
    padding: 18px;
  }
}

.react-calendar__tile--now {
  background-color: fade(@tory, 10%) !important;
}

.react-calendar__navigation .react-calendar__navigation__arrow:hover,
.react-calendar__navigation .react-calendar__navigation__label {
  background-color: transparent !important;
}

.react-calendar__tile--active {
  background-color: @darkbrown !important;
  color: @white !important;
}

.react-calendar__month-view__weekdays {
  font-family: "Montserrat", sans-serif;
  color: @rollingstone;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.react-calendar__navigation {
  border-bottom: 1px solid @shadow;
}

.react-calendar__navigation__label {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: @rollingstone;
}

.cal-arrow {
  display: inline-block;
  height: 24px;
  width: 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

//calendar end

.appt-header {
  margin-top: 32px;
  margin-bottom: 24px;
}

.appt-notice {
  margin-bottom: 24px;
}

.schedule-area {
  display: flex;
  flex-direction: column;

  @media @l {
    flex-direction: row;
  }
}

.schedule-head {
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
}

.calendar-wrap {
  @media @l {
    width: 471px;
    margin-right: 16px;
  }
}

.schedule-time-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  @media @m {
    margin: 48px 110px 0px 110px;
  }

  @media @l {
    margin: 0;
    width: 309px;
  }
}

.schedule-time {
  display: flex;
  flex-direction: column;
}

.schedule-day {
  font-size: 15px;
  flex: 1;
  align-self: flex-start;
}

.schedule-date {
  color: @rollingstone;
}

.schedule-close {
  align-content: flex-end;
}

.schedule-close button {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  width: 24px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.schedule-options {
  margin-top: 16px;
  margin-bottom: 16px;
}

.schedule-options .checkbox:not(:first-child) {
  margin-top: 16px;
}

.schedule-hours-title {
  margin-top: 16px;
  margin-bottom: 2px;
  font-size: 15px;
}

.schedule-hours {
  display: flex;
  flex-direction: row;
}

.hours-entry {
  width: 143px !important;
}

.time-am-pm {
  background-color: @white;
  color: @darkbrown;
  border: 1px solid @shadow;
  background: none;
  outline: none;
  padding: 8px;
  font-size: 16px;
  margin-left: 8px;
}

.time-am-pm.active {
  background-color: @torch;
  color: @white;
}

.save-appt-button {
  margin-top: 24px;
  width: 60%;
  align-self: center;

  @media @m {
    margin-top: 32px;
    width: 40%;
  }

  @media @l {
    width: 60%;
  }
}

.finished-content {
  margin-top: 16px;
}

.add-to-calendar {
  margin-top: 28px;
  background-position: left center;
  background-size: 24px;
  background-repeat: no-repeat;
  padding-left: 32px;
  font-size: 16px;
  align-self: center;
}

.select-a-day {
  margin-top: 32px;
  align-self: center;

  @media @m {
    margin-top: 48px;
  }

  @media @l {
    margin-top: 0px;
    margin-left: 16px;
  }
}
