@import "../../global.less";

.message-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.message-head .message-count {
  padding: 8px;
  border-radius: 4px;
  background-color: @lighttory;
  margin: 0px;
  font-size: 12px;
}

.message-block {
  display: flex;
  flex-direction: column;
  background-color: @white;
  border: 1px solid @light-border;
  padding: 16px;
  height: 0%;
}

.message-block ul.linked-list a {
  @media @l {
    padding: 8px 56px 8px 8px;
    background-position: right 10px top 10px;
  }
}
