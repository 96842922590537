@import "../../global.less";

.participate-hero {
  height: 240px;
  background-size: cover;
  background-position: center;
  max-width: 1440px;

  @media @m {
    height: 345px;
  }

  @media @l {
    height: 460px;
  }
}

.participate-wrap {
  margin-top: -32px;
  display: flex;
  flex-direction: column;
}

.participate-title-box {
  text-align: left !important;
  padding: 16px 24px !important;
  margin-bottom: 64px;

  @media @m {
    align-self: flex-start;
  }

  @media @l {
    padding: 32px 48px !important;
  }
}

.participate-title-box h1 {
  @media @l {
    font-size: 44px;
  }
}

.num-of-participants {
  margin-bottom: 24px;
  text-align: center;
  align-self: center;

  @media @m {
    margin-bottom: 32px;
  }

  @media @l {
    width: 700px;
  }

  @media @xl {
  }
}

.num-callout-bold {
  color: @torch;
  font-weight: bold;
}

.participate-desc {
  margin-bottom: 32px;

  @media @m {
    margin-bottom: 48px;
  }
}

.participate-journey {
  @media @l {
    padding: 0px 81px;
  }
}

.participate-journey-head {
  margin-bottom: 16px;
}

.participate-journey-desc {
  margin-bottom: 16px;

  @media @m {
    margin-bottom: 32px;
  }
}

.participate-search-box {
  width: 100%;
  margin-bottom: 32px;
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 44px;
  padding-left: 60px !important;

  @media @m {
    margin-bottom: 48px;
  }

  @media @l {
    font-size: 30px !important;
    margin-bottom: 64px;
  }
}

.participate-map {
  height: 193px;
  width: 100%;

  @media @m {
    height: 414px;
  }

  @media @l {
    height: 540px;
  }

  @media @xl {
    height: 719px;
  }

  @media @xxl {
    height: 810px;
  }
}
