@import "../../global.less";

.Guides---How-CL-Work---S {
  width: 91%;
  margin-left: 4.5%;
  margin-right: 4.5%;
  background-color: #f6f6f6;

  @media @m {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  @media @xl {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  @media @xxl {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}

.Div-How-CL-Work-cann2 {
  width: 100%;
}

.How-Clinical-Trials {
  width: 100%;
  margin-bottom: 16px;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: black;

  @media @m {
    width: 100%;
    font-size: 30px;
  }

  @media @l {
    width: 100%;
    font-size: 60px;
  }
}

.Clinical-trials-play {
  width: 100%;
  margin: 16px 0px 16px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: black;

  @media @m {
    width: 100%;
    margin-bottom: 47.8px;
  }

  @media @l {
    width: 100%;
    margin: 16px 0px 48px 0px;
  }

  @media @xl {
    margin: 16px 0px 16px 0px;
    width: 100%;
  }

  @media @xxl {
    width: 74%;
    margin: 16px 0px 16px 0px;
  }
}

.How-medicine-gets-fr-header {
  width: 100%;
  margin: 0 0px 24px 0px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: black;

  @media @m {
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 27.2px;
    font-size: 20px;
  }

  @media @l {
    font-size: 40px;
    line-height: normal;
  }

  @media @xl {
    margin: 32px 0px 16px 0px;
    width: 80%;
  }
}

.How-medicine-gets-fr {
  width: 99%;
  margin: 0 1% 32px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: black;

  @media @m {
    width: 102%;
    margin: 0 0px 0px 0px;
    font-size: 16px;
    line-height: 27.2px;
  }

  @media @l {
    font-size: 16px;
    line-height: normal;
  }

  @media @xl {
    width: 97%;
    margin: 16px 0px 16px 0px;
  }
}

.how-plan {
  width: 66%;
  height: 223px;
  margin: 0 17% 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media @m {
    width: 23%;
    margin: 32px 0px 0px 0px;
    float: left;
    height: 172px;
  }

  @media @xl {
    height: 223px;
  }
}

.font-20 {
  font-size: 20px;

  @media @m {
    margin-bottom: 16px !important;
    margin-top: 0px;
  }

  @media @l {
    line-height: 40px;
    font-size: 30px;
  }
}

.W-100 {
  width: 100%;

  @media @m {
    float: left;
  }

  @media @xl {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
}

.display-none-HCT {
  display: none;

  @media @l {
    display: block;
  }
}

.float-right {
  @media @m {
    float: right;
    width: 71.5%;
    margin-left: 4%;
    margin-right: 1.5%;
    margin-top: 68px;
  }

  @media @xxl {
    margin-top: 90px;
  }
}

.phase3 {
  @media @m {
    width: 69%;
    margin-right: 4%;
  }
}

.margin-btm24 {
  @media @m {
    margin-bottom: 24px !important;
  }
}

.margintop0 {
  @media @m {
    margin-top: 34px;
  }

  @media @xl {
    margin-top: 68px;
  }
}

.width-100 {
  @media @xl {
    width: 89.5%;
  }
}

.Results-Evaluated {
  margin-top: 88px;
}
