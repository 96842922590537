@import "../../global.less";
.PageSize-CookiePolicy {
  width: 91%;
  margin-left: 4.5%;
  margin-right: 4.5%;
  background-color: #f6f6f6;

  @media @m {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  @media @xl {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  @media @xxl {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}

.regular-text-container h1 {
  margin-top: 89px;
  line-height: 60px;
  letter-spacing: 0;
}

.regular-text-container h2 {
  margin-top: 48px;
  letter-spacing: 0;
}

.regular-text-container h3 {
  margin-top: 38px;
  letter-spacing: 0;
}

.regular-text-container p {
  margin-top: 24px;
  line-height: 27.2px;
}

.table-ul {
  padding-left: 0px;
  list-style: none;
}
.table-li {
  padding-left: 5px;
}
.table-link {
  color: @torch !important;
}
.table-Add-Inf-title {
  font-weight: bold;
}
.csl-table-table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: auto;
  margin-top: 64px !important;
}
.csl-table-td {
  position: relative;
  text-align: left;
  padding: 0px 10px 0px 8px;
  font-size: 15px;
  height: fit-content;
  width: 95%;
  float: left;
  @media @m {
    min-width: 0px;
    line-height: 18px;
    width: auto;
    float: none;
    border: 1px solid #ccc;
    vertical-align: middle;
    padding: 10px 10px 10px 7px;
  }
}
.csl-table-title {
  background: #ddd;
  @media @m {
    background: none;
  }
}
.csl-table-th {
  background: #ddd;
  padding: 7px 20px 7px 7px;
  font-size: 13px;
}
.table-head {
  display: none;
  @media @m {
    display: contents;
  }
}
.table-p {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
  font-weight: bold;
}
.cookie-csl-checkbox {
  margin-top: 64px !important;
  width: 100%;
  float: left;
}
.cookie-csl-checkbox p {
  float: left;
  margin-top: 0px;
  margin-right: 16px;
}
.cookie-csl-checkbox .checkbox {
  float: left;
  width: auto !important;
}
button.sign-in {
  margin-top: 64px;
  float: left;
  width: 120px !important;
}
