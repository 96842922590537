@import "../../global.less";

//gradient
@gradient-height: 79px;

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 100;
}

.header.light {
  position: relative;
  background-color: @white;
}

.header .gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  background-image: linear-gradient(to bottom, #000000cc, #00000033);
  height: @gradient-height;

  @media @m {
    height: @gradient-height + 31;
  }

  @media @xxl {
    height: @gradient-height + 25;
  }
}

.header.light .logo-container {
  display: flex;
  align-items: center;
}

.header.light .gradient {
  display: none;
}

.header .logo {
  position: relative;
  height: 88px;

  @media @m {
    height: 118px;
  }
}

.header.light .logo {
  height: 33px;
  padding: 24px 11px;
  vertical-align: middle;

  @media @m {
    height: 46px;
    padding: 32px 12px;
  }
}

.header .menu {
  display: flex;
  position: relative;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: @gradient-height;

  @media @m {
    height: @gradient-height + 31;
  }

  @media @xxl {
    height: @gradient-height + 25;
  }
}

.header .menu .deskmenu {
  display: none;
  text-transform: uppercase;
  margin-right: 16px;
  @media @l {
    display: block;
  }
}

.header .menu .deskmenu a {
  margin-left: 32px;
}

.header.light .menu .deskmenu a,
.header.light .menu .deskmenu button {
  color: @darkbrown;
}

.header.light .menu .deskmenu a.active,
.header.light .menu .deskmenu button.active {
  color: @white;
}

.header .menu .ham {
  display: flex;
  flex-direction: column;
  color: @white;
  font-size: 12px;
  align-items: center;
  cursor: pointer;

  @media @l {
    display: none;
  }
}

.header.light .menu .ham {
  color: @darkbrown;
}

.header .menu .ham img {
  height: 32px;

  @media @m {
    height: 44px;
  }
}

.top-sign-out {
  margin-left: 32px;
}
