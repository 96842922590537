@import "../../global.less";

.expanded-list-item {
  background-color: @white;
}

.expanded-list-item:not(:first-child) {
  margin-top: 16px;
}

.expanded-item-header {
  padding: 24px 32px;
  cursor: pointer;
  background-size: 44px;
  background-position: right 32px center;
  background-repeat: no-repeat;
  padding-right: 72px;
}

.expanded-item-content {
  display: none;
  padding: 16px 32px 24px 32px;
}

.expanded-item-content.active {
  display: block;
}
