@import "../../global.less";

ul.linked-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid @light-border;
}

ul.linked-list li {
  display: flex;
  border-bottom: 1px solid @light-border;
  padding-left: 0px;
  margin: 0px !important;
}

ul.linked-list a {
  display: block;
  width: 100%;
  color: @darkbrown;
  background-size: 20px;
  font-size: 16px;
  padding: 16px 56px 16px 8px;
  background-position: right 10px top 19px;
  background-repeat: no-repeat;
}

ul.linked-list a:hover {
  color: @torch;
  background-color: @pampas;
  text-decoration: underline;
}
