@import "../../global.less";

.regular-text-container {
  margin-left: 80px;
  margin-right: 80px;
}
.regular-text-container-Int {
  margin-left: 10px;
  margin-right: 10px;
}

.regular-text-container h1 {
  margin-top: 89px;
  line-height: 60px;
  letter-spacing: 0;
}
.regular-text-container-Int h1 {
  text-align: center;
  font-weight: bold;
  font-size: 18px !important;
}
.regular-text-container h2 {
  margin-top: 48px;
  letter-spacing: 0;
}

.regular-text-container h3 {
  margin-top: 38px;
  letter-spacing: 0;
}
.regular-text-container-Int h3 {
  font-weight: bold;
  font-size: 16px !important;
  margin-bottom: 5px;
  margin-top: 10px;
}
.regular-Center-Inti {
  text-align: center;
  font-size: 18px !important;
}
.regular-margin-Inti {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.regular-text-container-Int h2 {
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 16px !important;
}

.regular-text-container p {
  margin-top: 24px;
  line-height: 27.2px;
}
.CSL-Terms-link {
  display: block !important;
  text-decoration: underline;
  text-decoration-color: @torch;
  color: @torch !important;
  margin: 2px 5px 0px;
}
.CSl-Terms-float {
  float: left;
}
.margin-Bottom-Invitation {
  margin-bottom: 15px;
}
.Inv-H3 {
  margin-left: 5%;
  margin-right: 5%;
}
