@import "../../global.less";

.carousel.item {
  display: flex;
  flex-direction: column;
  background-color: @white;
  box-shadow: 0 4px 8px 0 @shadow-light;
  margin-bottom: 8px;
}

.carousel.item .pic {
  height: 220px;
  background-position: center center;
  background-size: cover;
  border-left: solid 1px @light-border;
  border-right: solid 1px @light-border;
  border-top: solid 1px @light-border;

  @media @m {
    height: 163px;
  }

  @media @l {
    height: 220px;
  }
}

.carousel.item .content {
  flex: 1;
  padding: 24px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  border-left: solid 1px @light-border;
  border-right: solid 1px @light-border;
  border-bottom: solid 1px @light-border;

  @media @m {
    padding: 24px 24px 28px 24px;
  }

  @media @l {
    padding: 24px 32px 28px 32px;
  }

  @media @xxl {
    padding: 24px 36px 28px 36px;
  }
}

.carousel.item .content .heading {
  margin: 0px;
  padding-bottom: 8px;
}

.carousel.item .content .posted {
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: @rollingstone;
}

.carousel.item .content p {
  flex: 1;
  margin: 0px;
  padding: 16px 0px 13px 0px;
}

.carousel.item .content a.more {
  justify-self: flex-end;
  display: inline-block;
  line-height: 0;
  align-self: flex-end;
  margin-right: 4px;
  margin-bottom: 4px;
  width: 37px;
  height: 37px;
  background-size: 100%;
}
