@import "../../global.less";
.techSupport-head-box {
  font-size: 18px;
  width: 88%;
  margin: 0px 5% 0px 5%;
  padding: 0px 1% 20px;
  @media @m {
    margin-top: 50px;
  }
}
.CSL-TechSup-Title {
  font-size: 30px;
  @media @l {
    font-size: 60px;
    font-weight: 200;
  }
}
.CSL-TechSup-TimeDiv {
  margin: 20px 50px;
}
.CSL-TechSup-p {
  margin-bottom: 15px;
  margin-top: 40px;
}
.CSL-TechSup-Margin {
  margin-bottom: 15px;
}
.CSL-TechSup-BreadCrumb {
  text-align: end;
  margin-right: 5%;
}
