@import "../../global.less";

.forgot-block {
  margin: 32px auto 0px auto;

  @media @m {
    width: 500px;
  }
}

.forgot-block h1 {
  text-align: center;
}

.forgot-sub-block {
  margin: 0px 43px;
  display: flex;
  flex-direction: column;
}

.forgot-sub-block p {
  margin: 32px 0px 24px 0px;
}

button.reset-button {
  margin-top: 64px;
}
