@import "../../global.less";
@import "../../components/learn-guide/glossary.less";
.Condition-Title {
  font-size: 30px;
  font-weight: 300;
  width: 100%;
  font-family: Montserrat;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  margin-top: 28px;
  letter-spacing: normal;
  color: @darkbrown;
  @media @m {
    width: 100%;
    font-size: 30px;
  }
  @media @l {
    font-size: 60px;
  }
}
.SUbTitle-Condition {
  @media @l {
    font-size: 40px;
  }
}
.COndition1-Answer {
  @media @l {
    width: 90% !important;
  }
  @media @xl {
    width: 81% !important;
  }
}
.Praesent-commodo-cur {
  width: 100%;
  margin: 16px 0px 32px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: @darkbrown;

  @media @m {
    width: 100%;
    margin-bottom: 40px;
  }

  @media @l {
    width: 100%;
    margin: 16px 0px 48px 0px;
  }

  @media @xl {
    margin: 16px 0px 16px 0px;
    width: 82%;
  }

  @media @xxl {
    width: 82%;
    margin: 16px 0px 16px 0px;
  }
}
.Bitmap-Condition {
  width: 338px;
  height: 35px;
  margin: 16px 0px 24px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media @m {
    width: 551px;
    height: 57px;
    margin: 16px 15% 16px 13%;
  }

  @media @l {
    width: 638px;
    height: 66px;
    margin: 16px 15% 24px 17%;
  }

  @media @xl {
    width: 822px;
    height: 85px;
    margin: 24px 15% 24px 17%;
  }
  @media @xxl {
    width: 822px;
    height: 85px;
    margin: 24px 15% 24px 20%;
  }
}
.More-on-Condition {
  width: 100%;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  margin-top: 28px;
  letter-spacing: normal;
  color: @darkbrown;
  @media @m {
    width: 100%;
    font-size: 20px;
  }

  @media @l {
    width: 100%;
    font-size: 40px;
    margin-bottom: 32px;
  }
}
.MoreConditionRectangle {
  display: inline-block;
  padding: 0 0px 24px 0;
  box-shadow: 0 4px 8px 0 rgba(226, 223, 218, 0.5);
  border: solid 1px @westar;
  background-color: @white;
  width: 85%;
  @media @m {
    width: 37%;
  }
  @media @l {
    width: 32%;
  }
}
.MoreConditionMargin {
  margin-right: 5%;
  @media @m {
    margin-right: 2%;
  }
  @media @l {
    margin-right: 1.5%;
  }
}
.MoreCOnditionDiv {
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.AdobeStock_225477126 {
  width: 100%;
  height: 163px;
  margin: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media @m {
    height: 220px;
  }
}
.Innovation-is-in-Our {
  width: 92%;
  margin: 24px 4% 8px 4%;
  color: @darkbrown;
  font-size: 20px;
  @media @m {
    width: 84%;
    margin: 24px 8% 8px 8%;
    white-space: break-spaces;
  }
}
.div-Jul-2020 {
  width: 92%;
  margin: 0px 4% 8px 4%;
  line-height: 1.67;
  opacity: 0.5;
  color: @rollingstone;
  font-weight: bold;
  @media @m {
    width: 84%;
    margin: 0px 8% 0px 8%;
  }
}
.Podcast-discusses-fi {
  font-size: 16px;
  white-space: break-spaces;
  @media @m {
    margin: 16px 8% 8px 8%;
  }
}
.Iconsic_circle-arrow-right-Torch {
  width: 44px;
  height: 44px;
  margin-top: 13px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px 4% 8px 4%;
  float: right;
  @media @m {
    width: 44px;
    margin: 0px 8% 8px 8%;
  }
  @media @l {
    width: 44px;
    height: 44px;
  }
  @media @xl {
    width: 44px;
    height: 44px;
  }
  @media @xxl {
    width: 44px;
    height: 44px;
  }
}
.CSL-Behring-News-U-Condition {
  display: none;
  @media @xxl {
    display: block;
  }
}
