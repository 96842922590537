@import "../../global.less";

a.img-button {
  align-self: flex-start;
  padding: 9px 18px 9px 14px !important;
}

a.img-button span {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: 100%;
  vertical-align: middle;
  position: relative;
  margin-right: 7px;

  @media @l {
    width: 44px;
    height: 44px;
  }
}
