@import "../../global.less";
.PageSize-Privacy {
  width: 91%;
  margin-left: 4.5%;
  margin-right: 4.5%;
  background-color: #f6f6f6;

  @media @m {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
  @media @l {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  @media @xl {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  @media @xxl {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}

.table-ul {
  padding-left: 0px;
  list-style: none;
  font-size: 16px !important;
}
.CSl-Table-ul {
  list-style: disc;
}
.CSl-Table-li {
  font-weight: normal;
  padding-left: 0px;
}
.table-li {
  padding-left: 5px;
}
.table-link {
  color: @torch !important;
}
.table-Add-Inf-title {
  font-weight: bold;
  margin-right: 5px !important;
}
.csl-table-table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: auto;
  color: @darkbrown;
  margin-top: 24px !important;
  font-size: 16px !important;
  width: 100%;
  @media @m {
    width: 70%;
  }
  @media @l {
    width: 55%;
  }
}
.csl-table-td {
  position: relative;
  text-align: left;
  padding: 0px 10px 0px 8px;
  height: fit-content;
  border: 1px solid @black;
  @media @m {
    min-width: 0px;
    line-height: 18px;
    width: auto;
    vertical-align: middle;
    padding: 10px 10px 10px 7px;
  }
}

.csl-table-th {
  background-color: #8db3e2;
  border: 1px solid @black;
  padding: 7px 20px 20px 7px;
  width: 50%;
}
.table-head {
  display: contents;
}
.table-p {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
  font-weight: bold;
}
.csl-checkbox {
  margin-top: 64px !important;
  float: left;
}
.csl-checkbox p {
  float: left;
  margin-top: 0px;
  margin-right: 16px;
}
.csl-checkbox .checkbox {
  float: left;
  width: auto !important;
}

.regular-text-container h1 {
  margin-top: 89px;
  line-height: 60px;
  letter-spacing: 0;
}

.regular-text-container h2 {
  margin-top: 48px;
  letter-spacing: 0;
}

.regular-text-container h3 {
  margin-top: 38px;
  letter-spacing: 0;
}

.regular-text-container p {
  margin-top: 24px;
  line-height: 27.2px;
}
.scroll-link {
  cursor: pointer;
  text-decoration: underline;
}
.CSL-Title-p {
  margin: 16px 0px;
}
.contact-div {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.contact-list {
  margin-top: 0px !important;
}
.CSL-p {
  color: @darkbrown;
  margin-top: 16px !important;
  font-weight: normal;
}
.CSl-Ul-List {
  list-style-image: none !important;
  margin-left: 12px;
}
.CSL-email {
  margin-left: 12px;
  text-decoration: underline;
  font-size: 16px !important;
}
.CSL-p-black {
  color: @darkbrown !important;
  font-weight: normal;
}
