@import "../../global.less";

.callout-box {
  display: flex;
  flex-direction: column;

  @media @l {
    flex-direction: row;
  }
}

.box-padding {
  padding: 24px 16px;

  @media @m {
    padding: 24px;
  }

  @media @l {
    padding: 24px 32px;
  }
}

.watch-img {
  cursor: pointer;
  background-position: center center;
  background-size: cover;
  height: 220px;

  @media @m {
    height: 351px;
  }

  @media @l {
    width: 390px;
    height: 300px;
  }
}

.watch-sub {
  display: block;
  color: @rollingstone;
  margin-bottom: 8px;

  @media @l {
    margin-bottom: 16px;
  }
}

.watch-link {
  margin-top: 24px;
  align-self: flex-end;

  @media @m {
    margin-top: 32px;
  }
}

.download-study-guide {
  display: block;
  margin-top: 24px;
}

.pdf-img {
  vertical-align: middle;
  display: inline-block;
  background-size: 100%;
  height: 24px;
  width: 24px;
  margin-right: 3px;
}

.box-spacing {
  margin-bottom: 32px;

  @media @m {
    margin-bottom: 48px;
  }
}

.study-overview {
  margin-bottom: 26px;

  @media @l {
    margin-bottom: 0px;
  }
}

.trial-study-guide {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  @media @m {
    margin-top: 48px;
  }

  @media @l {
    flex-direction: row;
  }
}

.trial-study-guide > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media @l {
    margin-bottom: 0px;
    margin-right: 24px;
  }
}

.trial-study-guide > div,
.trial-study-guide > ul {
  flex: 1;
}

.trial-study-guide .trial-study-desc {
  margin-top: 16px;
  margin-bottom: 18px;
}

.overview-header {
  margin-top: 32px;
  margin-bottom: 16px;

  @media @m {
    margin-top: 48px;
  }

  @media @l {
    margin-bottom: 24px;
  }
}

.study-area {
  display: flex;
  flex-direction: column;

  @media @l {
    flex-direction: row;
  }
}

.study-overview {
  @media @l {
    margin-right: 17px;
  }
}

.study-area .study-overview,
.study-area .study-guide {
  @media @l {
    flex: 1;
  }
}

.study-content {
  display: flex;
  flex-direction: column;
}

.study-content p {
  flex-grow: 1;
}

.dash-head-wrap {
  display: flex;
  flex-direction: column;
}

.dash-head-wrap .message-block {
  @media @l {
    width: 309px;
  }

  @media @xl {
    width: 304px;
  }
}

.screened-banner {
  background-repeat: no-repeat;
  background-color: @torch;
  background-size: 200px;
  background-position: center 24px;
  padding: 250px 16px 24px 16px;

  @media @m {
    background-size: 160px;
    background-position: 16px 24px;
    padding: 32px 32px 32px 195px;
  }

  @media @l {
    background-position: 16px bottom;
    background-size: 200px;
    padding: 32px 100px 32px 250px;
  }

  @media @xl {
    background-size: 300px;
    padding: 85px 110px 85px 433px;
  }
}

.screened-banner h4 {
  color: @white;
}

.visit-area-wrap {
  display: flex;
  flex-direction: column;

  @media @l {
    flex-direction: row;
  }
}

.dash-add-dates-wrap {
  display: block;
}

.dash-add-dates-box {
  display: block;

  @media @l {
    width: 240px;
  }
}

.add-dates-box-head {
  font-weight: bold;
  margin-bottom: 24px;
}

.add-dates-box-footer {
  margin-top: 24px;
  color: @steel;
  font-size: 14px;
}

.visit-content {
  flex: 1;
  margin-bottom: 32px;

  @media @l {
    margin-bottom: 0px;
    margin-right: 20px;
  }
}

.visit-options {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.visit-options button {
  font-size: 16px;
  padding: 16px 8px;
  border: 1px solid @shadow;
  color: @darkbrown;
  background-color: @white;
}

.visit-options button.active {
  background-color: @torch;
  color: @white;
}

.visit-options button:not(.active):hover {
  color: @darkbrown;
  background-color: @pampas;
}

.visit-options button:not(:first-child) {
  margin-left: 10px;
}

.trial-video-banner {
  margin-top: 48px;
  display: flex;
  flex-direction: column;

  @media @l {
    flex-direction: row;
  }
}

.trial-video-preview {
  height: 220px;
  width: 100%;
  align-items: stretch;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media @l {
    width: 146px;
    height: auto;
  }
}

.trial-video-content {
  background-color: @white;
  padding: 24px 16px;
  flex: 1;

  @media @l {
    display: flex;
    flex-direction: row;
    padding: 24px;
  }
}

.trial-video-content .inner {
  flex: 1;
}

.trial-video-content .video-length {
  color: @steel;
  font-size: 16px;
}

.trial-video-content .desc {
  margin-top: 8px;
}

.trial-video-content .watch-button {
  margin-top: 24px;

  @media @l {
    margin: 0;
  }
}

.trial-video-content .watch-button-wrap {
  @media @l {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: auto;
  }
}
