@import "../../global.less";
@import "../home/index.less";
.Featured-Page-S {
  width: 91%;
  margin-left: 4.5%;
  margin-right: 4.5%;
  background-color: @lightgrey;

  @media @m {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  @media @xl {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  @media @xxl {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}
.Featured-BackButton {
  width: 100%;
}
.Fetured-Page-Title {
  width: 100%;
  margin-bottom: 16px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: @darkbrown;
  margin-bottom: 24px;
  @media @m {
    width: 100%;
    font-size: 30px;
  }

  @media @l {
    width: 100%;
    font-size: 60px;
  }
}
.Bitmap-Condition {
  width: 338px;
  height: 35px;
  margin: 16px 0px 24px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media @m {
    width: 551px;
    height: 57px;
    margin: 16px 15% 16px 13%;
  }

  @media @l {
    width: 638px;
    height: 66px;
    margin: 16px 15% 24px 17%;
  }

  @media @xl {
    width: 822px;
    height: 85px;
    margin: 24px 15% 24px 17%;
  }
  @media @xxl {
    width: 822px;
    height: 85px;
    margin: 24px 15% 24px 20%;
  }
}
.LinkSeeMore-FA {
  width: 100%;
  height: 20px;
  display: none !important;
  margin: 0px 0px 1.2px 8px;
  padding-top: 14.8px;
  font-family: Montserrat;
  font-size: 16px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: @torch !important;
  text-align: center;
  margin-bottom: 14.8px;
  @media @l {
    display: block !important;
  }
}
.CSL-Behring-News-U-FA {
  font-size: 20px;
  margin-top: 32px;
  @media @l {
    font-size: 40px;
  }
}
.Listen-on-div {
  width: 60%;
  margin-left: 28%;
  margin-right: 12%;
  color: @darkbrown;
  text-align: center;
  margin-top: 2px;
  @media @m {
    width: 55%;
    margin-left: 33%;
  }
  @media @l {
    width: 53%;
    margin-left: 35%;
  }
  @media @xl {
    width: 49%;
    margin-left: 39%;
  }
}
.Listen-on-item {
  width: 250px !important;
  margin-top: 8px;
  padding-top: 0px;
  float: left;
  margin-bottom: 0px;
  display: block !important;
  text-align: start;
  @media @m {
    margin-top: 0px;
    width: 131px !important;
    margin-right: 16px;
  }
}
.Listen-on-Span {
  height: 20px;
  line-height: 18px;
  margin-bottom: 0px;
  text-align: start;
  display: block !important;
  width: 250px !important;
  margin-left: 8px;
  @media @m {
    width: 77px !important;
    margin-right: 16px;
    margin-left: 0px;
  }
}

.Apple-Podcasts {
  padding-top: 0px;
  float: left;
  display: block !important;
  margin-bottom: 0px;
  text-align: start;
  width: 250px !important;
  margin-top: 8px;
  @media @m {
    width: 79px !important;
    margin-top: 0px;
    margin-right: 16px;
    margin-left: 0px;
  }
}
.spotifypopup {
  padding: 20px 2% 20px;
  margin: 50px 2%;
  background-color: white;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0 16px 32px 0 #453f2e28;
}
.csl-modal-close-bar {
  padding-left: 0px;
  padding-top: 0px;
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  opacity: 1.5;
}
