@import "../../global.less";

.RectangleGuide {
  width: 105px;
  height: 40px;
  margin: 16px 0px 32px 0px;
  padding: 8px 22px 8px 8px;
  border-radius: 32px;
  border: solid 1px @torch;
  cursor: pointer;

  @media @m {
    margin: 32px 0px 32px 0px;
  }

  @media @l {
    margin: 32px 0px 32px 0px;
  }

  @media @xl {
    width: 141px;
    height: 64px;
    margin: 32px 0px 32px 0px;
  }

  @media @xxl {
    margin: 32px 0px 32px 0px;
  }
}

.OvalGUide {
  width: 19px;
  height: 19px;
  background-size: 100%;
  justify-self: flex-end;
  display: block;
  margin-left: 3px;
  float: left;

  @media @xl {
    width: 35px;
    height: 35px;
  }
}

.Tertiary-Button {
  width: 43px;
  height: 16px;
  /* padding-bottom: 37px; */
  margin: 3px 0 4px 8px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: @torch;
  float: right;

  @media @xl {
    width: 63px;
    height: 16px;
    font-size: 16px;
    margin-top: 10px;
  }
}

.Guide-breabrumb {
  display: none;
  width: auto;
  float: right;

  @media @l {
    display: block;
    margin: 0px 0px 32px 32px;
  }
  @media @xxl {
    margin: 0px 0px 30px 0px;
  }
}

.CSL-breadCrumb {
  width: 10%;
  height: 25px;
  margin: 0 2px 0 0;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: right;
  color: @torch !important;
}

.Path-breadCrumb {
  color: @lightergrey !important;
  width: 5%;
  height: 24px;
  margin: 1px 2px 0;
  padding: 5px 9px 6px 8px;
  font-size: 18px;
  font-weight: 500;
}

.Learn-breadCrumb {
  width: 20%;
  height: 25px;
  margin: 0 2px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: right;
  color: @torch !important;
}

.What-is-a-Clinical-T-breadCrumb {
  width: 40%;
  height: 25px;
  margin: 0 0 0 2px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: right;
  color: @lightergrey !important;
}
.BackButton-Margintop {
  margin-top: 32px;
  margin-bottom: 32px;
}
