@import "../../global.less";

.prescreen-profile-head {
  margin-top: 32px;
}

.prescreen-profile-subhead {
  margin-top: 32px;
}

.prescreen-nextsteps {
  margin-top: 16px;
}

.prescreen-create-profile-head {
  margin-top: 32px;
}

.prescreen-create-profile-req {
  margin-top: 24px;
}

.prescreen-profile-form-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.prescreen-form-section {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media @m {
    flex-direction: row;
  }
}

.prescreen-form-section:not(:first-child) {
  margin-top: 32px;
  @media @m {
    margin-top: 16px;
  }
}

.prescreen-form-section .form-field {
  width: 100%;
}

.prescreen-form-section .form-field:first-child {
  margin-right: 16px;
}

.prescreen-form-section .form-field:not(:first-child) {
  margin-top: 32px;
  @media @m {
    margin-top: 0px;
  }
}

.prescreen-contact-prefs {
  max-width: 500px;
}

.prescreen-contact-pref-head {
  margin-top: 32px;
  margin-bottom: 16px;
}

.prescreen-review-terms-head {
  margin-top: 32px;
}

.prescreen-review-box {
  background-color: @white;
  margin-top: 16px;
  border: 1px solid @shadow;
  padding: 16px;
  max-height: 360px;
  overflow-y: auto;
}

.prescreen-review-agree {
  margin-top: 16px;
}

.prescreen-review-button {
  margin-top: 32px;
  text-align: center;

  @media @m {
    margin-top: 64px;
  }
}

.prescreen-review-button button {
  width: 100%;
  @media @m {
    width: 50%;
  }
}
